import { AppBar, Box, Button, CssBaseline, Toolbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import contract from '../contracts/Oxmusic.json';
import staticSongContract from '../contracts/OxmusicStaticSong.json';

import { ethers } from 'ethers';
import { useMoralis, useNFTBalances } from 'react-moralis';
import { Link } from 'react-router-dom';
import { useMainContext } from '../MainContext/MainContext';
import MobileNavbar from './MobileNavbar';
import NetworkModal from '../Components/Modal/NetworkModal';

//import Moralis from "moralis/types";

//const contractAddress = '0xeca65b55d504bf1e15d6f7f5e374e58c6a7d9011'
const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
const staticSongAddress = process.env.REACT_APP_STATIC_CONTRACT_ADDRESS;
const abi = contract.abi;
const staticSongAbi = staticSongContract.abi;
const chainId = process.env.REACT_APP_CHAIN_ID;
const chain = process.env.REACT_APP_CHAIN_ID == 1 ? 'eth' : 'rinkeby';


export default function Navbar() {
  const { authenticate, isAuthenticated, user } = useMoralis();
  const { getNFTBalances, data, error, isLoading, isFetching } = useNFTBalances();

  const { holderUrls, focus, walletConnected, walletAccount, mintedStaticSongs } = useMainContext();

  const [getHolderUrl, setHolderUrl] = holderUrls;
  const [getFocusDetails, setFocusDetails] = focus;
  const [getWalletConnected, setWalletConnected] = walletConnected;
  const [getMintedStaticSongs, setMintedStaticSongs] = mintedStaticSongs;
  const [account, setAccount] = walletAccount;

  const phrases = ['0xmusic', 'generative music'];

  const [currentAccount, setCurrentAccount] = useState(null);
  const [currentAccountShort, setCurrentAccountShort] = useState('Connect Wallet');

  function refresh() {
    setWalletConnected({
      connect: true,
      isStatic: false,
      ss: [],
      sr: [],
      st: [],
      sgnr: [],
      smbb: [],
      gOver: -1,
      genre: -1,
      id: -1,
      len: -1,
      bg: -1,
      name: '',
    });
  }

  const disconnectWallet = () => {
    setCurrentAccount();
    setAccount();
    setCurrentAccountShort('Connect Wallet');
    setWalletConnected({
      connect: false,
      isStatic: false,
      ss: [],
      sr: [],
      st: [],
      sgnr: [],
      smbb: [],
      gOver: -1,
      genre: -1,
      id: -1,
      len: -1,
      bg: -1,
      name: '',
    });
  };

  const connectWalletHandler = async () => {
    const { ethereum } = window;

    try {
      if (!getWalletConnected.connect) {
        return;
      } else {
        setWalletConnected({
          connect: false,
          isStatic: false,
          ss: [],
          sr: [],
          st: [],
          sgnr: [],
          smbb: [],
          gOver: -1,
          genre: -1,
          id: -1,
          len: -1,
          bg: -1,
          name: '',
        });
      }

      const accounts = await ethereum.request({
        method: 'eth_requestAccounts',
      });
      let account = accounts[0];
      console.log('Found an account! address: ', account);
      setAccount(account);
      setCurrentAccount(account);
      setCurrentAccountShort(account.substring(0, 4) + '...' + account.substring(account.length - 4));
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const nftContract = new ethers.Contract(contractAddress, abi, signer);
      const staticSongContract = new ethers.Contract(staticSongAddress, staticSongAbi, signer);

      let moralisResult;
      let parsedImageDetails;
      let imageDetails;
      let focusDJDetails;

      const whiteList = new Set([
        '0xf0a8391b201d66388d3389e2ff721f5e1c1e5709',
        '0x5f7f97063405ecb088b9b69e4253c84d4e20e5d4',
        '0xcc3672611df7393a84b4de0541bf71c7cf056728',
        '0xd1ceaa8aeffe3f388aac8e9e8e89d2d3b8157884',
        '0xc92046ec68056a9be9b449ac71180a9077892bf7',
        '0xc658f3b9e92f83c4ff2f86a9e0495a24b94a4c16',
        '0x1c7329b8ca8a5ce4248e4bcd21a7598a21f68a70',
        '0x0519c0a42cd5b8cd65f64a4ec30a5dcf51334dc7',
        '0x83a398026880f018d3dcf4fa3c94bd5b3f2776f2',
        '0xef3ebf106f21b406d80154c1cf2b05214853e9d2',
        '0x338050301df259ab1aee79026e6e1bff7b10c899',
        '0xbe4e1923de1967d5a3882515e32aeebfe7c51652',
        '0x5b3900d5fcec6067b97259f1c4fd430b39952db8',
        '0x0526a4ed6bd07fab47dc3885b0f4982e9650bc45',
        '0xe9225f057a16f600c4ee2b6c169e93534381d0d0',
        '0xcd980b28732be9ddfaaf2b12b292bb07ebc86889',
        '0xdd39c8deaeacdcdde35bd48a469349b9346fe7fa',
        '0xd5a9c4a92dde274e126f82b215fccb511147cd8e',
        '0x47886f5343ebbc0678050383ce1caaa40861cce9',
        '0x17d19da0dacc522ac86e55db724cc7035e2355d9',
        '0xbd5a0c87c16c7b0afb24c7a0542c71faf3861284',
        '0x059cdc3da91d09bfb2e0d37983cc39cccc43b9c5',
        '0xc9d868c0676d88b1bd8dc34e64189cb61021064b',
        '0xcd125c95230c4d416eb017c654c92ab9bac51198',
        '0x8b4dde8a951b0e77ee2f01575b61fb0aad7b6184',
        '0xa5a3fe42279944da17670d40a7c9ff7fdf29d23c',
        '0xa7934b22dc239510f42d0d3e486aee2f121e7683',
        '0x48cd130949880d951c3846f653ddcbdd28a7a6f1',
        '0xded86db976fc9ae590b75d52b53212f586a45db2',
        '0xb49efc9ea89b256758abbb8d89d4206e6df75c34',
        '0x1563df6ba2e1b577c27183e6fad8684890d35392',
        '0x85c70d592ab79ec278eeb6819afa0dfd17681597',
        '0xd8ce0194d66f6c612ad46f4c36a313f775123ccb',
        '0x221dc635a5a1eada12ae550f699116026c234e61',
        '0x627f489e01fb281263f84e2226b98dba082155ae',
        '0x91e545f09a8519cc555672964b8fc38107409e65',
        '0x18cafc52777b8c4b0daa02e34158870dfcc332ae',
        '0x7e42f4bf5113cef00182f1fe8d7abfbdbc7d09b4',
        '0x22b45eaad2b11e828e6ce3aa8e4ff96a798d62be',
        '0x34e12651cdd498cd70aadc7a67ec32b48b053723',
      ]);

      //rin or eth

      const getNFTS = async () => {
        const userEthNFTs = await getNFTBalances({
          params: {
            chain,
            address: account,
            token_addresses: [contractAddress],
          },
          onSuccess: (x) => {
            // console.log("got results " + x);
            moralisResult = x;
          },
        }).then((x) => console.log('results'));

        imageDetails = await Promise.all(
          moralisResult.result.map(async (asset) => {
            const tokenId = parseInt(asset.token_id);
            const staticSongsUsed = parseInt(await staticSongContract.tokenIdToStaticSongsMinted(tokenId));
            const details = await nftContract.getImageDetails(tokenId);
            var imageUrl = await nftContract.getImageURL(tokenId);
            var name = await nftContract.getMasterFromTokenId(tokenId);
            imageUrl = imageUrl.replace(/["]+/g, '');
            return new Map([
              ['details', details],
              ['image_url', imageUrl],
              ['name', name],
              ['tokenId', tokenId],
              ['static_songs_used', staticSongsUsed],
            ]);
          }),
        );

        if (imageDetails.length === 0) {
          return;
        }

        focusDJDetails = {
          tokenId: imageDetails[0].get('tokenId'),
          dj: imageDetails[0].get('details')[0],
          id: parseInt(imageDetails[0].get('details')[1], 10),
          len: parseInt(imageDetails[0].get('details')[2], 10),
          bg: parseInt(imageDetails[0].get('details')[3], 10),
          staticSongsUsed: parseInt(imageDetails[0].get('static_songs_used'), 10),
        };

        parsedImageDetails = imageDetails.map((det) => {
          const tokenId = det.get('tokenId');
          const dj = det.get('details')[0];
          const hsl = parseInt(det.get('details')[1], 10);
          const len = parseInt(det.get('details')[2], 10);
          const records = parseInt(det.get('details')[3], 10);
          const staticSongsUsed = parseInt(det.get('static_songs_used'), 10);
          const name = det.get('name');
          return new Map([
            ['animation_url', `tonekingzwithlead.html?genre=${dj}&id=${hsl}&len=${len}&bg=${records}`],
            ['dj', dj],
            ['hsl', hsl],
            ['len', len],
            ['records', records],
            ['image_url', det.get('image_url')],
            ['name', name],
            ['tokenId', tokenId],
            ['static_songs_used', staticSongsUsed],
          ]);
        });
      };

      if (whiteList.has(account)) {
        await getNFTS();

        if (imageDetails.length === 0) {
          focusDJDetails = {
            dj: 2,
            id: 73,
            len: 3,
            bg: 3,
            staticSongsUsed: 0,
          };

          parsedImageDetails = [
            new Map([
              ['animation_url', 'tonekingzwithlead.html?genre=2&id=73&len=3&bg=3'],
              ['dj', 2],
              ['hsl', 73],
              ['len', 3],
              ['records', 3],
              [
                'image_url',
                'https://0xmusic.mypinata.cloud/ipfs/QmfL6oRoroEnvrV6yw6tZUgfhLdWgyaWb524V3Ar6gbykc/2/73/3/img.png',
              ],
              ['name', 'Dimend 287'],
              ['static_songs_used', 0],
            ]),
            new Map([
              ['animation_url', 'tonekingzwithlead.html?genre=6&id=37&len=3&bg=6'],
              ['dj', 6],
              ['hsl', 37],
              ['len', 3],
              ['records', 6],
              [
                'image_url',
                'https://0xmusic.mypinata.cloud/ipfs/QmfL6oRoroEnvrV6yw6tZUgfhLdWgyaWb524V3Ar6gbykc/6/37/6/img.png',
              ],
              ['name', 'Bach 285'],
              ['static_songs_used', 0],
            ]),
            new Map([
              ['animation_url', 'tonekingzwithlead.html?genre=4&id=309&len=4&bg=3'],
              ['dj', 4],
              ['hsl', 309],
              ['len', 4],
              ['records', 3],
              [
                'image_url',
                'https://0xmusic.mypinata.cloud/ipfs/QmfL6oRoroEnvrV6yw6tZUgfhLdWgyaWb524V3Ar6gbykc/4/309/3/img.png',
              ],
              ['name', 'Syn City 284'],
              ['static_songs_used', 0],
            ]),
            new Map([
              ['animation_url', 'tonekingzwithlead.html?genre=3&id=235&len=3&bg=6'],
              ['dj', 3],
              ['hsl', 235],
              ['len', 3],
              ['records', 6],
              [
                'image_url',
                'https://0xmusic.mypinata.cloud/ipfs/QmfL6oRoroEnvrV6yw6tZUgfhLdWgyaWb524V3Ar6gbykc/3/235/6/img.png',
              ],
              ['name', 'Drip 283'],
              ['static_songs_used', 0],
            ]),
            new Map([
              ['animation_url', 'tonekingzwithlead.html?genre=7&id=197&len=3&bg=3'],
              ['dj', 7],
              ['hsl', 197],
              ['len', 3],
              ['records', 3],
              [
                'image_url',
                'https://0xmusic.mypinata.cloud/ipfs/QmfL6oRoroEnvrV6yw6tZUgfhLdWgyaWb524V3Ar6gbykc/7/197/3/img.png',
              ],
              ['name', 'Serena 282'],
              ['static_songs_used', 0],
            ]),
            new Map([
              ['animation_url', 'tonekingzwithlead.html?genre=1&id=171&len=3&bg=6'],
              ['dj', 1],
              ['hsl', 171],
              ['len', 3],
              ['records', 6],
              [
                'image_url',
                'https://0xmusic.mypinata.cloud/ipfs/QmfL6oRoroEnvrV6yw6tZUgfhLdWgyaWb524V3Ar6gbykc/1/171/6/img.png',
              ],
              ['name', 'Athena 278'],
              ['static_songs_used', 0],
            ]),
            new Map([
              ['animation_url', 'tonekingzwithlead.html?genre=0&id=339&len=2&bg=6'],
              ['dj', 0],
              ['hsl', 339],
              ['len', 2],
              ['records', 6],
              [
                'image_url',
                'https://0xmusic.mypinata.cloud/ipfs/QmfL6oRoroEnvrV6yw6tZUgfhLdWgyaWb524V3Ar6gbykc/0/339/6/img.png',
              ],
              ['name', '420 268'],
              ['static_songs_used', 0],
            ]),
          ];
        }
      } else {
        await getNFTS();
      }
      if (parsedImageDetails && parsedImageDetails.length > 0) {
        const nameToTokenId = await songMintHandler();

        if (!getWalletConnected.isStatic) {
          setHolderUrl({
            djUrls: parsedImageDetails,
            mainUrl: `tonekingzwithlead.html?genre=${focusDJDetails.dj}&id=${focusDJDetails.id}&len=${focusDJDetails.len}&bg=${focusDJDetails.bg}`,
            initializing: false,
          });
          setFocusDetails({
            focusUrl: `tonekingzwithlead.html?genre=${focusDJDetails.dj}&id=${focusDJDetails.id}&len=${focusDJDetails.len}&bg=${focusDJDetails.bg}`,
            focusDJ: parsedImageDetails[0],
            tokenId: focusDJDetails.tokenId,
            isStatic: false,
            records: focusDJDetails.bg,
            staticSongsUsed: focusDJDetails.staticSongsUsed,
          });
        } else {
          const staticSongsUsed = parseInt(
            await staticSongContract.tokenIdToStaticSongsMinted(getWalletConnected.tokenId),
          );
          const tokenId = nameToTokenId.get(getWalletConnected.name);

          const imageDetailsWithStatic = new Map([
            ['dj', getWalletConnected.genre],
            ['hsl', getWalletConnected.id],
            ['len', getWalletConnected.len],
            ['records', getWalletConnected.bg],
            ['static', 1],
            ['ss', getWalletConnected.ss],
            ['sgnr', getWalletConnected.sgnr],
            ['sr', getWalletConnected.sr],
            ['st', getWalletConnected.st],
            ['smbb', getWalletConnected.smbb],
            ['song', '0xSong ' + 0],
            ['name', getWalletConnected.masterName],
            ['master_name', getWalletConnected.masterName],
            ['song_name', getWalletConnected.name],
            ['static_songs_used', staticSongsUsed],
            ['gover', getWalletConnected.gOver],
            ['song_tokenId', tokenId],
          ]);

          setHolderUrl({
            djUrls: parsedImageDetails,
            mainUrl: `tonekingzwithlead.html?genre=${getWalletConnected.genre}&id=${getWalletConnected.id}&len=${getWalletConnected.len}&bg=${getWalletConnected.bg}`,
            initializing: false,
          });

          setFocusDetails({
            focusUrl: `tonekingzwithlead.html?genre=${getWalletConnected.genre}&id=${getWalletConnected.id}&len=${getWalletConnected.len}&bg=${getWalletConnected.bg}`,
            focusDJ: imageDetailsWithStatic,
            tokenId: getWalletConnected.tokenId,
            isStatic: true,
            records: getWalletConnected.bg,
            staticSongsUsed: staticSongsUsed,

            //                midiUri: midiUri
          });
        }
      }
    } catch (err) {
      setWalletConnected(false);
      setAccount();
      console.error(err);
    }
  };

  const songMintHandler = async () => {
    const { ethereum } = window;

    try {
      const accounts = await ethereum.request({
        method: 'eth_requestAccounts',
      });
      let account = accounts[0];
      // console.log("Found an account! address: ", account);

      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const nftContract = new ethers.Contract(staticSongAddress, staticSongAbi, signer);
      const parentNftContract = new ethers.Contract(contractAddress, abi, signer);

      let moralisResult2;
      let imageDetails2 = [];
      let nameToTokenId = new Map();

      //rin or eth

      const getNFTS = async () => {
        const userEthNFTs = await getNFTBalances({
          params: {
            chain,
            address: account,
            token_addresses: [staticSongAddress],
          },
          onSuccess: (x) => {
            // console.log("got results for static songs " + x);
            moralisResult2 = x;
          },
        }).then((x) => console.log('results static'));

        imageDetails2 = await Promise.all(
          moralisResult2.result.map(async (asset) => {
            const tokenId = parseInt(asset.token_id);
            const songName = await nftContract.songName(tokenId);
            nameToTokenId.set(songName, tokenId);
            const parentTokenId = await nftContract.parentTokenId(tokenId);
            const masterName = await parentNftContract.getMasterFromTokenId(parentTokenId);

            const args = await nftContract.args(tokenId);
            const details = await parentNftContract.getImageDetails(parentTokenId);
            const staticSongsUsed = parseInt(await nftContract.tokenIdToStaticSongsMinted(parentTokenId), 10);

            const genre = details[0];
            const id = parseInt(details[1], 10);
            const len = parseInt(details[2], 10);
            const bg = parseInt(details[3], 10);

            var uriArgs = 'http://localhost?ss=' + args;
            var uri = new URL(uriArgs);
            var ss = uri.searchParams
              .get('ss')
              .split(',')
              .map(function (input) {
                return parseInt(input);
              });
            var sgnr = uri.searchParams
              .get('sgnr')
              .split(',')
              .map(function (input) {
                return parseInt(input);
              });
            var sr = uri.searchParams
              .get('sr')
              .split(',')
              .map(function (input) {
                return parseFloat(input);
              });
            var smbb = uri.searchParams
              .get('smbb')
              .split(',')
              .map(function (input) {
                return parseInt(input);
              });
            var st = uri.searchParams
              .get('st')
              .split(',')
              .map(function (input) {
                return parseInt(input);
              });

            var gOver = parseInt(uri.searchParams.get('gOver'));

            var imageUrl = await parentNftContract.getImageURL(parentTokenId);
            imageUrl = imageUrl.replace(/["]+/g, '');
            return new Map([
              ['dj', genre],
              ['hsl', id],
              ['len', len],
              ['records', bg],
              ['name', songName],
              ['master_name', masterName],
              ['song_name', songName],
              ['image_url', imageUrl],
              ['static', 1],
              ['ss', ss],
              ['sgnr', sgnr],
              ['sr', sr],
              ['st', st],
              ['smbb', smbb],
              ['static_songs_used', staticSongsUsed],
              ['tokenId', parseInt(parentTokenId)],
              ['song_tokenId', tokenId],
              ['gover', gOver],
            ]);
          }),
        );
      };

      await getNFTS();

      if (imageDetails2.length != 0) {
        setMintedStaticSongs({ djUrls: imageDetails2 });
      }

      return nameToTokenId;
    } catch (err) {
      console.error(err);
    }
  };

  const [isNetwork, setIsNetwork] = useState(false);

  const handleIsNetworkOpen = () => setIsNetwork(true);
  const handleIsNetworkClose = () => setIsNetwork(false);

  let network = window?.ethereum?.networkVersion;

  useEffect(() => {

    if (!account) {
      console.log('connect your account')

    } else {
      if (chainId !== network) {
        handleIsNetworkOpen()
      } else {
        handleIsNetworkClose()
      }
    }
  }, [account, network])
  // console.log('window.ethereum.networkVersion', network, chainId);
  //checkWalletIsConnected()
  connectWalletHandler();


  return (
    <Box>
      <NetworkModal handleClose={handleIsNetworkClose} open={isNetwork} />

      <AppBar
        position='static'
        sx={{
          bgcolor: 'transparent',
          color: '#414141',
          px: { xs: 16, sm: 26, md: 60, lg: 100 },
          boxShadow: 0,
          mt: 30,
        }}>
        <CssBaseline />
        <Toolbar>
          <Typography
            component={Link}
            to='/'
            variant='h3'
            sx={{
              fontFamily: 'Betatron',
              fontSize: { xs: 24, md: 36 },
              textDecoration: 'none',
              color: '#414141 !important',
            }}>
            0xmusic
          </Typography>
          <Box sx={{ flex: 1 }} />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              '& a': {
                textDecoration: 'none',
                color: '#414141',
                mr: { md: 40, lg: 64 },
                display: { xs: 'none', md: 'flex' },
              },
            }}>
            <Link to='/masterpiece'>
              <Typography variant='h5'>The Masterpiece</Typography>
            </Link>
            {/* <Link to='#'>
              <Typography variant='h5'>0xSong Lab</Typography>
            </Link>
            <Link to='#'>
              <Typography variant='h5'>About</Typography>
            </Link> */}
            <Button
              variant='contained'
              onClick={() => {
                if (!currentAccount) {
                  refresh();
                } else {
                  disconnectWallet();
                }
              }}
              sx={{
                fontSize: { xs: 16, md: 16 },
                bgcolor: '#F4F4F4 !important',
                borderRadius: '100px',
                color: '#414141',
                fontFamily: 'CA Saygon Text',
                fontWeight: '400',
              }}>
              {currentAccountShort}
            </Button>

            <MobileNavbar />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
