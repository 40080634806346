import { Button } from '@mui/material';

export default function MButton({ title, sx, ...other }) {
  return (
    <Button
      variant='outlined'
      sx={{
        fontSize: 16,
        border: '3px solid #2993FF',
        borderRadius: '100px',
        color: '#414141',
        fontFamily: 'CA Saygon Text',
        fontWeight:400,
        mt: 40,
        ...sx,
        '&:hover': {
          border: '3px solid #2993FF',
        },
      }}
      {...other}>
      {title}
    </Button>
  );
}
