import { Box, Link, Typography } from "@mui/material";

export default function NewsCard({card , idx}) {
  return (
    <Box sx={{ display:'flex' }}>
      <Box
        component="img"
        src={card.image}
        sx={{ width: 82, height: 84, mr: 10 }}
      />
      <Box>
        <Typography sx={{ fontFamily: "Coanda TRIAL" }}>{card.title}</Typography>
        <Typography variant="h6">
          {card.desc}
        </Typography>
        <Link
          href={card.link}
          target="_blank"
          sx={{
            fontWeight: "700",
            color: "#2993FF",
            textDecoration: "none",
            mt:10,
            fontFamily:'Coanda TRIAL',
          }}
        >
          Read more
        </Link>
      </Box>
    </Box>
  );
}
