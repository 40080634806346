import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import React from "react";
import { cardBg } from "../../style/style";

export default function LeaderBoardTableCard({ name, count }) {
  return (
    <Box sx={{ }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap:40,
          mt:10,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold !important", textTransform:'capitalize' }}>
          {name}
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold !important" }}>
          {count}
        </Typography>
      </Box>
    </Box>
  );
}
