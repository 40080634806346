import { useState } from "react";
import GallerySection from "../Components/GalarySection";
import MasterPiceModal from "../Components/Modal/MasterPiceModal";
import Community from "../section/Community";
import Feature from "../section/Feature";
import HeroSection from "../section/HeroSection";
import Meet from "../section/Meet";
import News from "../section/News";
import Roadmap from "../section/Roadmap";

export default function LandingPage() {
  const [openMasterPice, setOpenMasterPice] = useState(true);
  const handleClose = () => setOpenMasterPice(false);
  const handleOpen = () => setOpenMasterPice(true);

  return (
    <>
      <MasterPiceModal open={openMasterPice} handleClose={handleClose} />

      <HeroSection />
      <GallerySection />
      <Feature />
      <Meet />
      <Roadmap />
      <Community />
      <News />
    </>
  );
}
