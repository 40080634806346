import CloseIcon from '@mui/icons-material/Close';
import { Divider, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import * as React from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 550,
  bgcolor: '#fff',
  boxShadow: '4px 4px 8px #847c7c',
  borderRadius: '12px',
  outline:'none'

};


export default function NetworkModal({ handleClose, open }) {
  return (
    <div>
      <Modal
        // hideBackdrop={true}
        open={open}
        onClick={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        sx={{ bgcolor: '#fdfbfb17', boxShadow: 0 }}>
        <Box sx={style}>
          <Box sx={{ display: 'flex', px: 24, py: 10, justifyContent: 'space-between' }}>
            <Typography variant='h6' sx={{ fontWeight: 'bold', mt: 20, fontSize: '20px !important' }}>
              Network Error!
            </Typography>
            <IconButton onClick={handleClose} sx={{ color: '#000' }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />
          <Box sx={{ p: 24, display: 'flex', justifyContent: 'center', alignItems: 'center', pb: 45, pt: 30 }}>
            <Typography variant='h6' sx={{ fontWeight: 'bold', mt: 20 }}>
              Please switch to the {process.env.REACT_APP_CHAIN_ID === 4 ? 'Rinkeby' : 'Ethereum main'} network in order to vote.
            </Typography>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
