import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { cardBg } from "../style/style";

const ROADMAP_CARD_DATA = [
  {
    id: 1,
    chipTitle: "Birth",
    title: "0xDJs are born and the music starts from now to eternity.",
    desc: "The genesis collection of 0xDJs were minted on 2/2/2022 at 2:22 PM EST. The collection sold out instantly, and music NFTs were forever changed.",
  },
  {
    id: 2,
    chipTitle: "Home studio",
    title: "Establishing a home studio for 0xDJs",
    desc: "0xDJs are integrated with 0xmusic.com, allowing collectors to connect their wallets and experience the audiovisual beauty of their art under optimal conditions, browse their collections, and view the metadata of each piece.",
  },
  {
    id: 3,
    chipTitle: "Creative tools",
    title: "Tools to further creativity",
    desc: "In this phase, the 0xSong Lab is launched to turn collectors into producers, empowering them with the full potential of CC0 licensing. Connecting their wallets activates a new interface, with download access to MIDI representations of their 0xDJ’s songs. The only limit is a producer’s imagination, as they use their creativity to remix and build on top of the music composed by their 0xDJs.",
  },
  {
    id: 4,
    chipTitle: "Song Minting",
    title: "0xSong minting",
    desc: "The 0xSong Lab evolves to the next level with the addition of 0xSong Minting. Every 0xDJ is endowed with a special trait called a ‘Record’, which gives its collector the power to mint an NFT of a song they love, saving it before it’s gone forever. Once all of a 0xDJ’s records are used, no more songs can be minted from that DJ. Since there are trillions of potential songs that can be created, minting 0xSongs lets producers ‘immortalize’ moments in time and create unique, shareable artifacts.",
  },
  {
    id: 5,
    chipTitle: "The Masterpiece",
    title: "It all comes together",
    desc: `The creativity of the 0xmusic community is immortalized into a masterpiece: The 0xmusic Genesis Album.
    Songs that were discovered and minted by holders, and community creations made from MIDI files of 0xDJ songs will form the two sides of this album. The community will not only create the album, but also curate it: both holders and non-holders will vote to decide which songs make the cut.`,
  },
];

export default function MAccordions() {
  const [expanded, setExpanded] = React.useState("panel5");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={{ mt: { xs: 13, md: 40 } }}>
      {ROADMAP_CARD_DATA.map((card, idx) => {
        return (
          <Accordion
            key={card.id + idx}
            expanded={expanded === `panel${card.id}`}
            onChange={handleChange(`panel${card.id}`)}
            sx={{
              "&.MuiAccordion-root:before": {
                display: "none",
              },
              "&.MuiAccordion-root": {
                borderRadius: 0,
                boxShadow: 0,
              },
              borderRadius: 0,
              "& .MuiAccordionSummary-content": {
                m: 0,
              },
              "& .MuiAccordionSummary-content.Mui-expanded": {
                m: 0,
              },
              "&.MuiAccordion-root.Mui-expanded": {
                m: "0px !important",
              },
            }}
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 0,
                  borderRadius: "25px",
                  bgcolor: "#F4F4F4",
                  width: "fit-content",
                  py: 7,
                  px: 16,
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                {idx < ROADMAP_CARD_DATA.length - 1 && (
                  <Box
                    sx={{
                      bgcolor: "#F4F4F4",
                      // bgcolor: 'red',
                      width: 2,
                      height: 15,
                      position: "absolute",
                      top: 36,
                      left: 20,
                      zIndex: 999,
                    }}
                  />
                )}

                <Typography variant="h6">
                  {card.id}. {card.chipTitle}
                </Typography>
                <CheckCircleIcon
                  sx={{ display: idx === 4 ? "none" : "block", ml: 6 }}
                />
                {idx === 4 && (
                  <Box component="img" src="/clock.svg" sx={{ ml: 6 }} h />
                )}
              </Box>
            </AccordionSummary>

            <AccordionDetails sx={{}}>
              <Box
                sx={{
                  p: 16,
                  ...cardBg,
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {card.title}
                </Typography>

                <Typography variant="h6" sx={{ mt: 15 }}>
                  {card.desc} <br />
                  {//idx === 4 && "Stay tuned as we reveal..."}
                  }</Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}
