import { Box } from '@mui/material';


// ----------------------------------------------------------------------


export default function SvgIconStyle({ src, sx }) {
    return (
        <Box
            component="span"
            sx={{
                color: '#fff',
                width: { xs: 17, lg: 18, xl: 24 },
                height: { xs: 17, lg: 18, xl: 24 },
                display: 'inline-block',
                bgcolor: 'currentColor',
                mask: `url(${src}) no-repeat center / contain`,
                WebkitMask: `url(${src}) no-repeat center / contain`,
                ...sx,
            }}
        />
    );
}
