import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { groupBySong } from './../utils/index';

const SONG_DB_URL = process.env.REACT_APP_SONG;

export const useQuerySongs = (userAccount = '') => {
  return useQuery('songData', async () => {
    const { data: serverRes } = await axios.get(SONG_DB_URL);

    const songs = serverRes.songs;


      songs?.sort(function compare(a, b) {
        var dateA = a?.song_id;
        var dateB = b?.song_id;

        // console.log("🚀 ~ file: AudioSection.jsx ~ line 44 ~ compare ~ dateA", dateA, dateB)
        return dateB - dateA;
      });


    const songsWithVote = [ ...songs, ...serverRes.songsVote];

    const groupedData = groupBySong(songsWithVote);

    return groupedData;
  });
};

export const addSong = async ({ song, account, hasUserVoted }) => {
  const songData = {
    user_wallet: account,
    song_id: song.song_id,
  };

  return hasUserVoted
    ? axios.delete(SONG_DB_URL, {
      data: songData,
    })
    : axios.post(SONG_DB_URL, songData);
};

export const useMutationSong = (userAccount = '') => {
  const queryClient = useQueryClient();
  return useMutation(addSong, {
    /** Optimistic Update Start */
    onMutate: async ({ song, account, hasUserVoted }) => {
      const { song_id } = song;

      await queryClient.cancelQueries('songData');

      // return previous data on error
      const previousData = queryClient.getQueryData('songData');

      queryClient.setQueryData('songData', (oldQueryData) => {
        let dataObject = { ...oldQueryData };

        if (!hasUserVoted) {
          dataObject[song_id].push({
            song_id,
            user_wallet: account,
            timeStamp: Date.now(),
          });
        } else {
          const array = [...dataObject[song_id]];
          const filteredArray = array.filter((item) => {
            return item.user_wallet !== account && item.song_id == song_id;
          });
          dataObject[song_id] = filteredArray;
        }
        return dataObject;
      });
      //   // ** roll back in case mutation errors out
      return { previousData };
    },
    // handle error of server
    onError: (_err, _updatingVote, context) => {
      queryClient.setQueryData('songData', context.previousData);
    },

    // update from server
    onSettled: () => {
      queryClient.invalidateQueries('songData');
    },
    //   /**Optimistic Update End */
  });
};
