const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  contract: null,
};

const cartSlice = createSlice({
  name: "cart",
  initialState: initialState,
  reducers: {
    audioMusic(state, action) {
      // console.log(state, action);
    },
    videoMusic(state, action) {
      // console.log('music', state, action)
    },
  },
});

export const { audioMusic, videoMusic } = cartSlice.actions;
export default cartSlice.reducer;
