import { Box, Typography } from '@mui/material';
import MAccordions from '../Components/MAccordion';

export default function Roadmap() {
  return (
    <Box
      sx={{
        maxWidth: 646,
        width: '100%',
        mx: 'auto',
        mt: 110,
        px: 16,
      }}>
      <Typography variant='h3'>Roadmap</Typography>
      <MAccordions />
    </Box>
  );
}
