import ReactAudioPlayer from "react-audio-player";
import "../style/style.css";
import { useState } from "react";
import { Box, Button } from "@mui/material";

function ReactMusicPlayer({ playerRef, src }) {
  const [play, setPlay] = useState(true);
  // const audioUrl =
  // "https://www.bensound.com/bensound-music/bensound-memories.mp3";
  const audioUrl = src;

  const handlePlayAudio = (e) => {
    e.type = 'play'
    console.log(e.type)
    setPlay(e.type);
    console.log(play);
  };

  return (
    <Box>
      {/* <Button onClick={handlePlayAudio}>play Music</Button> */}
      <ReactAudioPlayer
        ref={(element) => {
          playerRef.current = element;
        }}
        style={{ alignSelf: "center" }}
        autoPlay={false}
        loop={false}
        muted={false}
        src={audioUrl}
        onPlay={(e)=>handlePlayAudio(e)}
        preload="metadata"
        onPause={(e)=>e.type=play}
        // preload="none"
        onCanPlay={play}
        controls
        className="audioPlayer"
      />
    </Box>
  );
}

export default ReactMusicPlayer;
