import { Box, IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import SvgIconStyle from '../Components/SvgStyle';
import { cardBg } from '../style/style';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  width: '100%',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  ...cardBg,
  bgcolor: '#fff',
  textAlign: 'center',
  height: 100,
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  gap: 20,
};

export default function MobileNavbar() {
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={open ? handleClose : handleClick}
        sx={{
          display: { xs: 'block', md: 'none' },
          ml: { xs: 0, sm: 17 },
        }}>
        <Box component='img' src={`/assets/svg/${open ? 'navClose' : 'menu'}.svg`} sx={{ width: 24 }} />
      </IconButton>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{ sx: { width: '100%', mt: 16 } }}>
        
        <MenuItem onClick={handleClose}sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Link to='/' style={{textDecoration:'none', color:'#414141'}}>
            <Typography variant='h5'>Home</Typography>
          </Link>
        </MenuItem>

        <MenuItem onClick={handleClose}sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Link to='/masterpiece' style={{textDecoration:'none', color:'#414141'}}>
            <Typography variant='h5'>The Masterpiece</Typography>
          </Link>
        </MenuItem>
        <MenuItem sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <IconButton component='a' target='_blank' href='https://opensea.io/collection/0xmusic'>
            <SvgIconStyle src='/assets/svg/yeart.svg' sx={{ color: '#414141', width: 30, height: 30 }} />
          </IconButton>
          <IconButton component='a' target='_blank' href='https://twitter.com/0x_music'>
            <SvgIconStyle src='/assets/svg/twitterSmall.svg' sx={{ color: '#414141', width: 30, height: 30 }} />
          </IconButton>
          <IconButton component='a' target='_blank' href='http://discord.gg/0xmusic'>
            <SvgIconStyle src='/assets/svg/discordSmall.svg' sx={{ color: '#414141', width: 30, height: 30 }} />
          </IconButton>
          <IconButton component='a' target='_blank' href='https://medium.com/@0xmusic.art'>
            <SvgIconStyle src='/assets/svg/map.svg' sx={{ color: '#414141', width: 30, height: 30 }} />
          </IconButton>
        </MenuItem>
      </Menu>
    </>
  );
}
