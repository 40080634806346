import { Box } from '@mui/material';
import SliderCard from '../Cards/SliderCard';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import { Keyboard, Mousewheel, Navigation } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './slider.css';
// import 'swiper/css/pagination';

export default function CardSlider() {
  const SLIDER_DATA_CARD = [
    {
      id: 0,
      image: 'tonekingzwithlead.html?genre=4&id=80&len=2&bg=3',
      title: 'DJ Syn City',
      desc: 'Syn City mixes breezy chords over glitch beats, creating nostalgia- inducing melodies that immediately transport you back to distant memories and hot summer nights.',
    },
    {
      id: 1,
      image: 'tonekingzwithlead.html?genre=3&id=222&len=2&bg=3',
      title: 'DJ Drip',
      desc: 'Drip is a master of playful Afrobeats that get your head nodding and your feet moving. These tunes tap into everyone’s innate sense of rhythm, and you might catch yourself grooving out for hours.',
    },
    {
      id: 2,
      title: 'DJ Bach',
      image: 'tonekingzwithlead.html?genre=6&id=80&len=2&bg=6',
      desc: 'Bach plays generative counterpoint in a minor diatonic scale, which gives his music a more introspective feel. This is heavy listening, suited to music lovers and fans of classical forms.',
    },
    {
      id: 3,
      title: 'DJ Handel',
      image: 'tonekingzwithlead.html?genre=5&id=320&len=2&bg=6',
      desc: 'Handel plays generative counterpoint constructed in a major diatonic scale; a recognizable pillar of Western musical history. Listeners are provided a deep sense of resolution with each bend and turn.',
    },
    {
      id: 4,
      title: "DJ Serena",
      image:'tonekingzwithlead.html?genre=7&id=357&len=2&bg=3',
      desc: "Serena employs similar arrangements to Syn City, but these beats veer more contemplative than nostalgic, while intermittently providing resolution. They provide a cinematic soundtrack to life’s slower moments.",


    },

    {
      id: 5,
      title: 'DJ Athena',
      image: 'tonekingzwithlead.html?genre=1&id=260&len=2&bg=3',
      desc: "Athena's music is playful, frenetic, and constantly shifting to new places. This style hews closely to that of synth pop electronic genres and gets you going with its upbeat vibes.",
    },
    {
      id: 6,
      title: 'DJ 420',
      image: 'tonekingzwithlead.html?genre=0&id=357&len=2&bg=3',
      desc: '420 is inspired by avant-garde and progressive rock music of the 1970s. These mixes are replete with atonal and non-diatonic harmonies, featuring syncopated polyrhythmic lines layered on top of each other.',
    },
    {
      id: 7,
      title: 'DJ Dimend',
      image: 'tonekingzwithlead.html?genre=2&id=20&len=2&bg=3',
      desc: 'Dimend’s mixes are inspired by traditional bluegrass forms. This music is funky, rhythmic, and hypnotic, which makes it suitable for both soft background playback and more involved listening on your feet.',
    },
  ];

  return (
    <Box
      sx={{
        pl: 0,
        textAlign: { xs: '-webkit-center', md: 'left' },
      }}>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        initialSlide={3}
        centeredSlides={true}
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        keyboard={true}
        // pagination={{
        //   clickable: true,
        // }}
        modules={[Navigation, Mousewheel, Keyboard]}
        breakpoints={{
        500:{
          slidesPerView:  1.5,
        },
                  700: {
            slidesPerView: 2.5,
          },
          900: {
            slidesPerView: 2.5,
          },

          1200: {
            slidesPerView: 3.5,
          },
          1500: {
            slidesPerView: 5.5,
          },

        }}

        className='mySwiper'>
        {SLIDER_DATA_CARD.map((data, idx) => (
          <SwiperSlide key={data.id + idx}>
            {' '}
            <SliderCard  card={data} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}
