import axios from 'axios';
import { ethers } from 'ethers';
import { useMoralisWeb3Api } from 'react-moralis';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { filteredVoted, getTokenDetails, groupBy } from './../utils/index';
import contract from '../contracts/Oxmusic.json';
import staticSongContract from '../contracts/OxmusicStaticSong.json';

const UP_VOTE_URL = process.env.REACT_APP_UP_VOTE;
const staticSongAddress = process.env.REACT_APP_STATIC_CONTRACT_ADDRESS;
const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
const abi = contract.abi;
const staticSongAbi = staticSongContract.abi;

const chain = process.env.REACT_APP_CHAIN_ID == 1 ? 'eth' : 'rinkeby';
export const useQueryNfts = () => {
  const Web3Api = useMoralisWeb3Api();

  return useQuery('countData', async () => {
    const options = {
      chain,
      address: staticSongAddress,
    };
    const nftOwners = await Web3Api.token.getNFTOwners(options);
    const uniqueOwners = [...new Set(nftOwners?.result.map((item) => item.owner_of))];
    const promises = uniqueOwners.map(async (account) => {
      const options = {
        chain,
        address: account,
        token_address: staticSongAddress,
      };
      const polygonNFTs = await Web3Api.account.getNFTsForContract(options);
      return polygonNFTs;
    });
    const resolvedPromises = await Promise.all(promises);
    let newArray = [];
    const tokenArrays = resolvedPromises.map((tokenWithMetaData, idx) => {
      newArray.push(tokenWithMetaData.result);
    });

    newArray = newArray.flat();

    const { data: serverRes } = await axios.get(UP_VOTE_URL);

    const groupedData = groupBy(serverRes);

    return { countData: groupedData, newArray };
  });
};

export const addVote = async ({ card, account, hasUserVoted }) => {
  const tokenData = {
    user_wallet: account,
    token_id: card.token_id,
  };

  return hasUserVoted
    ? axios.delete(UP_VOTE_URL, {
        data: tokenData,
      })
    : axios.post(UP_VOTE_URL, tokenData);
};

export const useMutationVote = () => {
  const queryClient = useQueryClient();
  return useMutation(addVote, {
    /** Optimistic Update Start */
    onMutate: async ({ card, account, hasUserVoted }) => {
      const { token_id } = card;
      await queryClient.cancelQueries('countData');
      const previousData = queryClient.getQueryData('countData');

      queryClient.setQueryData('countData', (oldQueryData) => {
        let dataObject = { ...oldQueryData.countData };


        if (!hasUserVoted) {
          if (!dataObject?.[token_id]) {
            dataObject = { [token_id]: [] };
          }
          dataObject[token_id].push({
            token_id,
            user_wallet: account,
            timeStamp: Date.now(),
          });
        } else {
          const array = [...dataObject[token_id]];
          const filteredArray = array.filter((item) => {
            return item.user_wallet !== account && item.token_id === token_id;
          });
          dataObject[token_id] = filteredArray;
        }

        const newQueryData = { ...oldQueryData, countData: dataObject };
        return newQueryData;
      });
      // ** roll back in case mutation errors out
      return { previousData };
    },

    onError: (_err, _updatingVote, context) => {
      queryClient.setQueryData('countData', context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries('countData');
    },
    //   /**Optimistic Update End */
  });
};

// --------------------------------------------

export const useQuerySingleNft = ({ tokenId, account }) => {
  const Web3API = useMoralisWeb3Api();

  let network = window?.ethereum?.networkVersion;
  const queryKey = ['fetch-by-tokenId', tokenId, account, network];
  const queryFn = async () => {
    // let account;
    // if (network) {
    //   account = await window.ethereum.request({ method: 'eth_requestAccounts' });
    //   account = account[0];
    // }

    const options = {
      address: contractAddress,
      token_id: tokenId,
      chain,
    };
    const tokenIdMetadata = await Web3API.token.getTokenIdMetadata(options);
    let metadata = {
      dj: '',
      hsl: '',
      cycleLength: 0,
      records: '',
      imageUrl: '',
      name: ' ',
      tokenId,
      static_songs_used: ' ',
    };

    const djToOrientation = {
      Southpaw: 0,
      Dexter: 2,
      Vanward: 5,
      dexter: 2,
      vanward: 5,
      southpaw: 7,
    };
    const djCycleLength = {
      Large: 0,
      Medium: 3,
      Short: 2,
      large: 0,
      medium: 3,
      short: 2,
    };
    if (Number(network) !== 1 || !account) {
      if (tokenIdMetadata.metadata) {
        const parsedMetaData = JSON.parse(tokenIdMetadata.metadata);
        let imgUrl = parsedMetaData['animation'];
        imgUrl = imgUrl.split('/');

        let attributes = {};
        parsedMetaData.attributes.forEach((attribute) => {
          attributes = { ...attributes, ...attribute };
        });
        metadata = {
          // name: parsedMetaData.name + tokenId,
          name: parsedMetaData.name,
          dj: djToOrientation[attributes['Orientation']],
          hsl: attributes['HSL Palette'],
          cycleLength: Number(djCycleLength[attributes['Cycle length']]),
          records: attributes['Records'],
          imageUrl: imgUrl[5],
          tokenId,
          static_songs_used: '0',
        };
      } else {
        const uriMetadata = tokenIdMetadata.token_uri;
        const data = getTokenDetails(uriMetadata);

        let imgUrl = data['songUrl'];
        imgUrl = imgUrl.split('/');

        metadata = {
          name: data.songName + tokenId,
          dj: djToOrientation[data['Orientation']],
          hsl: data.hsl,
          cycleLength: djCycleLength[data.cycleLength],
          records: data['records'],
          imageUrl: imgUrl[5],
          tokenId,
          static_songs_used: '0',
        };
      }
    } else {

      const provider = new ethers.providers.Web3Provider(window?.ethereum);
      const signer = provider.getSigner();
      const nftContract = new ethers.Contract(contractAddress, abi, signer);
      const staticSong = new ethers.Contract(staticSongAddress, staticSongAbi, signer);

      let staticSongsUsed = await staticSong.tokenIdToStaticSongsMinted(tokenId);
      staticSongsUsed = staticSongsUsed.toString();

      const details = await nftContract.getImageDetails(tokenId);
      var imageUrl = await nftContract.getImageURL(tokenId);
      var name = await nftContract.getMasterFromTokenId(tokenId);

      const dj = details[0].toString();
      const hsl = details[1].toString();
      const len = details[2].toString();
      const records = details[3].toString();


      metadata = {
        dj,
        hsl,
        cycleLength: Number(len),
        records,
        imageUrl: `tonekingzwithlead.html?genre=${dj}&id=${hsl}&len=${len}&bg=${records}`,
        name: name,
        tokenId,
        tokenId,
        static_songs_used: staticSongsUsed,
      };
    }

    return metadata;
  };

  return useQuery(queryKey, queryFn, {
    enabled: !!tokenId,
    onError: (error) => console.log('error', 'Error while fetching Object by Token Id', error),
  });
};
