import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TwitterIcon, TwitterShareButton } from 'react-share';
import MButton from '../MButton';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: 550,
    bgcolor: '#fff',
    boxShadow: '4px 4px 8px #e6e0e0',
    p: 24,
    borderRadius: '12px',
  outline:'none'

};

export default function TwitterShare({ songName, handleClose, open }) {
    // console.log("🚀 song", songName)

    return (
        <div>
            <Modal
                hideBackdrop={true}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ bgcolor: '#fdfbfb17', boxShadow: 0 }}
            >
                <Box sx={style}>
                    <Box sx={{ display:'flex', justifyContent:'space-between' }}>
                    <Typography variant='h6' sx={{ fontWeight: 'bold', mt: 20 }}>Thanks for voting!</Typography>
                    <IconButton onClick={handleClose} sx={{ color:'#000' }}>
                    <CloseIcon />
                    </IconButton>
                    </Box>
                    <Typography id="modal-modal-title" variant="h6" sx={{ mt: 10 }}>
                        Your vote enters you into a raffle to win a 0xDJ. Could we ask you to spread the word on Twitter?
                    </Typography>
                    <TwitterShareButton
                        title={`I just voted to include ${songName} in the @0x_music genesis album. This is the community's collective effort to build a masterpiece blending human creativity & the 0xmusic algorithm`}
                        url={"https://0xmusic.com/#/masterpiece"}
                        hashtags={[""]}
                    >
                        <MButton
                        sx={{ fontWeight:'bold', px:24 }}
                            title="Sure!"
                        />
                    </TwitterShareButton>
                </Box>
            </Modal>
        </div>
    );
}
