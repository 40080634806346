import { configureStore } from "@reduxjs/toolkit";
// import logger from "redux-logger";

import appSlice from "./slice/app";

export const store = configureStore({
  reducer: {
   appSlice,
  },

  // devTools: import.meta.env.DEV,
});

export default store;
