import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LeaderBoardTableCard from '../Components/Cards/LeaderBoardTableCard';
import { useQuerySongs } from '../hooks/useAudio';
import { useQueryNfts } from '../hooks/useData';
import { useMainContext } from '../MainContext/MainContext';
import { cardBg } from '../style/style';
import { getObjUrl } from '../utils';

export default function LeaderBoard() {
  // states
  const [newSongsArray, setNewSongsArray] = useState([]);
  // const [hasSort, setHasSort] = useState(false);

  const [newSvgArray, setNewSvgArray] = useState([]);
  // const [hasSortSvg, setHasSortSvg] = useState(false);

  // Section A
  const { isLoading: isNFTLoading, data: NFTData } = useQueryNfts();
  const { countData, newArray: cardArray } = NFTData || {};

  // Section B

  const { walletAccount } = useMainContext();
  const [account] = walletAccount;
  const { isLoading, data: songsArray = [] } = useQuerySongs(account);


  useEffect(() => {
    let newArray2 = [];

    Object.keys(songsArray)?.map((song_id, idx) => {
      const song = songsArray[song_id][0];
      const count = songsArray[song_id].length - 1;

      newArray2.push({ songName: song.name, songCount: count });
    });
    // const newArray = newArray2
    const sortItems = newArray2.sort((a, b) => parseFloat(b.songCount) - parseFloat(a.songCount));

    setNewSongsArray(sortItems);

    const svgArray = [];

    cardArray?.map((card, idx) => {
      const { name } = getObjUrl(card?.token_uri);

      const count = countData[card.token_id]?.length || 0;

      svgArray.push({ svgName: name, svgCount: count });
    });
    const sortItems2 = svgArray.sort((a, b) => parseFloat(b.svgCount) - parseFloat(a.svgCount));
    setNewSvgArray(sortItems2);
  }, [songsArray, cardArray, countData]);

  // descending
  // const handleSongsSort = () => {
  // if (hasSort) {
  //   const sortItems = newSongsArray.sort(
  //     (a, b) => parseFloat(b.songCount) - parseFloat(a.songCount)
  //   );
  //   setNewSongsArray(sortItems);
  //   setHasSort(false);
  // } else {
  //   const sortItems = newSongsArray.sort(
  //     (a, b) => parseFloat(a.songCount) - parseFloat(b.songCount)
  //   );
  //   setNewSongsArray(sortItems);
  //   setHasSort(true);
  // }
  // };

  // const handleSvgSort = () => {
  // if (hasSortSvg) {
  //   const sortItems = newSvgArray.sort(
  //     (a, b) => parseFloat(b.svgCount) - parseFloat(a.svgCount)
  //   );
  //   setNewSvgArray(sortItems);
  //   console.log(sortItems)
  //   setHasSortSvg(false);
  // } else {
  //   const sortItems = newSvgArray.sort(
  //     (a, b) => parseFloat(a.svgCount) - parseFloat(b.svgCount)
  //   );
  //   setNewSvgArray(sortItems);
  //   setHasSortSvg(true);
  // }
  // };
  return (
    <Box>
      <Typography variant='h3'>Leaderboard</Typography>

      <Typography variant='h6' sx={{ mt: 24 }}>
        0xsongs and Community Creations with the most votes will be included in the{' '}
        <Typography component='span' sx={{ textTransform: 'capitalize' }}>
          0xmusic Genesis Album
        </Typography>
        . Below are the current leaders.
      </Typography>

      <Grid
        item
        container
        sx={{
          mt: 80,
          '& div': {
            px: { sm: 12 },
          },
        }}>
        <Grid xs={12} sm={12} md={5.8} sx={{}}>
          <Typography variant='h3'>Side A</Typography>
          <Box
            sx={{
              ...cardBg,
              mt: 30,
              p: { xs: 16, sm: 30 },
              height: 600,
              overflow: 'auto',
            }}>
            {isNFTLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}>
                <Box component='img' src='/LoadingSpinner.gif' width='180' height='180' />
              </Box>
            ) : (
              newSvgArray?.map((card, idx) => {
                return <LeaderBoardTableCard name={card.svgName} count={card.svgCount} />;
              })
            )}
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={5.8} sx={{ mt: { xs: 30, md: 0 } }}>
          <Typography variant='h3'>Side B</Typography>
          <Box
            sx={{
              ...cardBg,
              mt: 30,
              mb: { xs: 20, sm: 0 },
              p: { xs: 16, sm: 30 },
              height: 600,
              overflow: 'auto',
            }}>
            {
              // <Button
              //   variant="text"
              //   onClick={handleSongsSort}
              //   fullWidth
              //   sx={{ fontWeight: "bold", color: "#000" }}
              // >
              //   Sort
              // </Button>
            }

            {isLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}>
                <img src='/LoadingSpinner.gif' width='180' height='180' />

                {/* <CircularProgress /> */}
              </Box>
            ) : (
              <Box sx={{ py: 10 }}>
                {newSongsArray.map((song, idx) => {
                  return <LeaderBoardTableCard name={song.songName} count={song.songCount} />;
                })}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
