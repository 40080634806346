import CloseIcon from "@mui/icons-material/Close";
import { Divider, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Button } from "bootstrap";
import * as React from "react";
import MButton from "../MButton";
import { Link as NavLink } from "react-router-dom";

const style = {
  position: "absolute",
  top: {xs:"50%",sm:"50%", md:'40%'},
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: 550,
  bgcolor: "#fff",
  boxShadow: "4px 4px 8px #847c7c",
  borderRadius: "12px",
  overflow:'hidden',
  outlineColor: '#fff'
};

export default function MasterPiceModal({ handleClose, open }) {
  return (
    <div>
      <Modal
        // hideBackdrop={true}
        open={open}
        // onClick={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ bgcolor: "#fdfbfb17", boxShadow: 0 }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              px: 24,
              py: 10,
              justifyContent: "space-between",
              gap:20,
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", mt: 20, fontSize: "20px !important" }}
            >
              We're in the final phase of the 0xmusic party: The Masterpiece
            </Typography>
            <IconButton onClick={handleClose} sx={{ color: "#000",mt:15, height:36 }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />
          <Box
            sx={{
              p: 24,
              //   display: "flex",
              //   justifyContent: "center",
              //   alignItems: "center",
              pb: 45,
              pt: 30,
            }}
          >
            <Typography variant="h6" sx={{ mt: 0 }}>
              <Typography
                component="span"
                sx={{ fontWeight: "bolder !important" }}
              >
                If you're a 0xDJ holder,
              </Typography>{" "}
              now's the time to mint songs from your 0xDJ or submit your MIDI
              creations for inclusion in the 0xmusic Genesis Album.
            </Typography>

            <Typography variant="h6" sx={{ mt: 16 }}>
              <Typography
                component="span"
                sx={{ fontWeight: "bolder !important" }}
              >
                Both holders and non-holders
              </Typography>{" "}
              can vote to curate the album.
            </Typography>
            <MButton
              component={NavLink}
              to="/masterpiece"
              sx={{
                fontWeight: "bold",
                px: 24,
                color: "#000 !important",
              }}
              title="Vote Now"
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
