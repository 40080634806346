import { Link } from "@mui/material";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export default function FeatureCard({ card, idx }) {
  const { desc } = card;
  const [disc, setDisc] = useState([]);

  useEffect(() => {
    if (idx === 6) {
      let FDisc = desc.split("CC0 license");
      setDisc(FDisc);
    }
  }, []);

  // console.log(disc);
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 420,
        boxShadow: 11,
        px: 32,
        pb: idx === 2 ? 20 : 70,
        pt: 30,
        mx: { xs: "auto", lg: 0 },
        height: "100%",
      }}
    >
      <Box>
        <Box
          component="img"
          src={card.image}
          sx={{ width: 80, height: 80, mb: 10 }}
        />
        <Typography variant="h6" sx={{ fontWeight: 700, mt: 8 }}>
          {card.title}
        </Typography>
        {idx === 6 ? (
          <Typography variant="h6" sx={{ mt: 8 }}>
            {disc[0]}{" "}
            <Link href="https://creativecommons.org/share-your-work/public-domain/cc0">
              CC0 license
            </Link>{" "}
            {disc[1]}
          </Typography>
        ) : (
          <Typography variant="h6" sx={{ mt: 8 }}>
            {card.desc}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
