import { Divider } from "@mui/material";
import { Box, Button, Grid, Typography } from "@mui/material";

export default function Community() {
  return (
    <Box
      sx={{
        maxWidth: 840,

        mt: { xs: 200, md: 110 },
        mx: "auto",
        px: 16,
      }}
    >
      <Typography variant="h3">Community</Typography>

      {/* Social links */}
      <Grid item container xs={12} sx={{ mt: 40 }}>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            gap: 10,
            display: "flex",
            cursor: "pointer",
            textDecoration: "none",
            color: "#212529",
            pb:30,
            pr:{lg:25},
            borderBottom:'2px solid #f6f6f6',
            "&:hover": { color: "#212529" },
          }}
          component="a"
          target="_blank"
          href="http://discord.gg/0xmusic"
        >
          <Box
            component="img"
            src="/assets/svg/discord.svg"
            sx={{ width: 62, height: 64, p: 5,}}
          />
          <Box>
            <Typography variant="h6">
              Hop into our Discord to get the latest updates, meet fellow
              collectors, discuss music, and listen to 0xDJs in real time
            </Typography>
            <Button sx={{ fontWeight: "normal", color: "#2993FF" }}>
              Join our Discord
            </Button>
          </Box>
          <Divider />
        </Grid>
        <Box sx={{ flex: 1 }} />
        <Grid item xs={12} md={5} sx={{ mt: { xs: 40, md: 0 } }}>
          <Box
            sx={{
              gap: 10,
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              pb:30,
              borderBottom:'2px solid #f6f6f6',
              "&:hover": { cursor: "pointer" },
            }}
            component="a"
            target="_blank"
            href="https://twitter.com/0x_music"
          >
            <Box
              component="img"
              src="/assets/svg/twitter.svg"
              sx={{ width: 72, p: 10 }}
            />
            <Typography variant="h6" color="#2993FF">
              Follow our Twitter
            </Typography>
          </Box>

          <Box
            sx={{
              gap: 10,
              display: "flex",
              alignItems: "center",
              mt: 40,
              textDecoration: "none",
              pb:30,
              borderBottom:'2px solid #f6f6f6',
              "&:hover": { cursor: "pointer" },
            }}
            component="a"
            target="_blank"
            href="https://medium.com/@0xmusic.art"
            
          >
            <Box
              component="img"
              src="/assets/svg/roadMap.svg"
              sx={{ width: 72, p: 10 }}
            />
            <Typography variant="h6" color="#2993FF">
              Follow our Medium
            </Typography>
          </Box>
        </Grid>
        <Box sx={{ flex: 1 }} />
      </Grid>
    </Box>
  );
}
