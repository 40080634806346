import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HeadTable from './HeadTable';
import { Box } from '@mui/material';

export default function TableAccordion({ tokenId, account }) {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (

        <Box sx={{ mt: { xs: 30, sm: 10 }, }}>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}
                sx={{
                    "&.MuiAccordion-root:before": {
                        display: "none",
                    },
                    "&.MuiAccordion-root": {
                        borderRadius: 0,
                        boxShadow: 0,
                    },
                    borderRadius: 0,
                    "& .MuiAccordionSummary-content": {
                        m: 0,
                        px: 16,
                    },
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                        m: 0,
                    },
                    "&.MuiAccordion-root.Mui-expanded": {
                        m: "0px !important",
                        minHeight: '48px !important',
                        px: 3,
                    },
                }}
            >
                <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{
                        display: "flex",
                        pr: 16,
                        gap: 0,
                        borderRadius: expanded ? "8px 8px 0px 0px" : '8px',
                        // bgcolor: !expanded ? "#F4F4F4" : '#fff',
                        // bgcolor:'#fff',
                        width: "98%",
                        mx: 'auto',
                        py: 7,
                        justifyContent: "center",
                        position: "relative",
                        // borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
                        // boxShadow: "3px -3px 6px rgba(0, 0, 0, 0.1)",
                        minHeight: '48px !important',
                    }}
                >
                    <Typography variant='h6' sx={{ fontSize: '17px !important', color: '#747373', fontWeight: 100, ml: -14, borderBottom: '1px solid #b5aeaefa', px: `4px` }}>
                        Traits
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <HeadTable tokenId={tokenId} account={account} />
                </AccordionDetails>

            </Accordion>

            {(tokenId > 777 && !expanded) && <HeadTable />}

        </Box>
    );
}
