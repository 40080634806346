import { Box, IconButton, Typography } from "@mui/material";

export default function Footer() {
  return (
    <Box
      sx={{
        px: { xs: 16, sm: 26, md: 60, lg: 100 },
        bgcolor: "#414141",
        pt: 33,
        mt: 100,
        pb: 40,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h4" color="#fff" sx={{ fontFamily: "Betatron" }}>
          0xMusic
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 8 }}>
          <IconButton
            component="a"
            target="_blank"
            href="https://opensea.io/collection/0xmusic"
          >
            <Box component="img" src="/assets/svg/yeart.svg" />
          </IconButton>
          <IconButton
            component="a"
            target="_blank"
            href="https://twitter.com/0x_music"
          >
            <Box component="img" src="/assets/svg/twitterSmall.svg" />
          </IconButton>
          <IconButton
            component="a"
            target="_blank"
            href="http://discord.gg/0xmusic"
          >
            <Box component="img" src="/assets/svg/discordSmall.svg" />
          </IconButton>
          <IconButton
            component="a"
            target="_blank"
            href="https://medium.com/@0xmusic.art"
          >
            <Box component="img" src="/assets/svg/map.svg" />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ display: "none", mx: "auto", maxWidth: 343, width: "100%" }}>
        <Box
          component="img"
          src="/assets/image/footerImage.png"
          sx={{ mt: 33, width: "100%" }}
        />
      </Box>
    </Box>
  );
}
