import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import FeatureCard from "../Components/Cards/FeatureCard";

export default function Feature() {
  const [arrayLength, setArrayLength] = useState(3);

  const FEATURE_CARD_DATA = [
    {
      id: 0,
      title: "This smart contract has a degree in music",
      desc: "The 8 0xDJs in our genesis collection are a breakthrough in generative creativity, built with the instincts and experience of real musicians. Each one creates an endless stream of songs in its own genre, true to rules of music theory and composition.",
      image: "/assets/image/Union.png",
    },
    {
      id: 1,
      title: "Art that belongs in a museum, and on a stage",
      desc: "Tapping Play creates a brand new song and brings the stunning visuals to life. Tapping again makes another, and destroys all memory of the previous song. This eternal march of creation and destruction is the essence of life itself, now playing out on the blockchain.",
      image: "/assets/image/Frame.png",
    },
    {
      id: 2,
      title: "Can’t stop, won’t ever stop",
      desc: "The art is rendered from IPFS, but it can always be reconstituted from methods exposed on the smart contract",
      image: "/assets/image/dec.png",
    },
    {
      id: 4,
      title: "Your very own 24/7 hit factory",
      desc: "Already got your NFT? Visit the 0xSong Lab and gain access to the stems being created by your 0xDJ. You can use these unlimited MIDI files to remix and produce songs of your own in tools like GarageBand. 0xDJs do the hard work, so anyone can start to enjoy creating music.",
      image: "/assets/image/feature1.png",
    },
    {
      id: 5,
      title: "The only NFTs to mint their own records",
      desc: "Heard a tune so good it absolutely must be shared? Each 0xDJ comes with a limited number of “Records”, which let you immortalize great tunes as their own 0xSong NFTs. These are yours to keep, sell, or use as you wish. 0xmusic empowers you to experience being artist, producer, and publisher all at once.",
      image: "/assets/image/feature4.png",
    },
    {
      id: 6,
      title: "Fully compatible with the future",
      desc: "0xMusic is played within your web browser in real time using the web audio standard, in lossless quality. The accompanying animations are also produced live using free and open web technologies. We recommend using a laptop/desktop with sufficient processing capabilities for the very best experience.",
      image: "/assets/image/feature3.png",
    },
    {
      id: 7,
      title: "Putting passion over permission",
      desc: "We’ve created beautiful code, but strongly believe it should be for everyone to enjoy. Whether you want to use a 0xDJ in a metaverse music concert, create derivatives, or sell a song to Nike for their next commercial, it’s your choice. Learn how the CC0 license empowers you to create.",
      image: "/assets/image/feature2.png",
    },
  ];

  const handleLoading = () => {
    setArrayLength(FEATURE_CARD_DATA.length);
  };

  return (
    <Box sx={{ px: { xs: 16, md: 40, lg: 100 }, mt: 155 }}>
      <Typography variant="h3">Features</Typography>
      <Grid
        item
        spacing={{ md: 15, xl: 0 }}
        container
        sx={{ justifyContent: "center" }}
      >
        {FEATURE_CARD_DATA.map((data, idx) => {
          if (idx < arrayLength) {
            return (
              <Grid
                key={data.id + data.title}
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                sx={{ mt: 20 }}
              >
                <FeatureCard card={data} idx={idx} />
              </Grid>
            );
          }
        })}
      </Grid>
      <Box
        sx={{
          textAlign: "center",
          mt: 40,
          display: arrayLength === 3 ? "block" : "none",
        }}
      >
        <Button
          onClick={handleLoading}
          sx={{
            mx: "auto",
            fontSize: 16,
            borderRadius: 25,
            boxShadow: 23,
            px: 24,
            py: 6,
            fontWeight: "normal",
            color: "#414141",
          }}
        >
          <AddIcon sx={{ mr: 5 }} /> Load more
        </Button>
      </Box>
    </Box>
  );
}
