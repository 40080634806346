// export const groupBy = (items) => {
//   const result = items.reduce(function (previous, next) {
//     const id = next.token_id;
//     const index = previous.findIndex((item) => id === item.token_id);
//     if (index !== -1) {
//       previous[index]['count'] += 1;
//     } else {
//       previous.push({
//         token_id: next.token_id,
//         count: 1,
//       });
//     }

//     return previous;
//   }, []);

//   const object = result.reduce((obj, item) => Object.assign(obj, { [item.token_id]: item.count }), {});

//   return object;
// };

export const groupBySong = (items) => {
  // console.log(items);

  const obj = {};
  items.map((item) => {
    if (!obj[item.song_id] && !Array.isArray(obj[item.song_id])) {
      // console.log('item', item);

      obj[item.song_id] = [];
      obj[item.song_id].push(item);
    } else {
      obj[item.song_id].push(item);
    }
  });

  return obj;
  console.log('obj', obj);

};
export const groupBy = (items) => {
  // console.log(items);

  const obj = {};
  items.map((item) => {
    if (!obj[item.token_id] && !Array.isArray(obj[item.token_id])) {
      // console.log('item', item);

      obj[item.token_id] = [];
      obj[item.token_id].push(item);
    } else {
      obj[item.token_id].push(item);
    }
  });

  return obj;
  console.log('obj', obj);

};

export const filteredVoted = function (arrayOfObject, owner, tokenId) {
  // console.log(arrayOfObject ,account , tokenId )
  const checkUsername = (obj) => {
    return obj.user_wallet.toLowerCase() === owner.toLowerCase() && obj.token_id === tokenId;
  };
  return arrayOfObject.some(checkUsername);
};

export const getObjUrl = (uri, id) => {
  let text = uri;
  // console.log(text  )
  if (!text) {
    if (id === "31") {
      return {
        url: 'tonekingzwithlead.html?genre=7&id=166&len=2&bg=3&static=1&ss=0,17,21,15,1,9,15,13,11,7,0,8,9,0,3,0,6,4,5,4,1,0,0,0,10,19,11,0,10,15,17,0,12,3,13,1,2,3,3,0,2,2,5,4,1,0,0,0&sgnr=1,4,1,0,5,2,0,0,1,1,1,0,0&sr=&st=0,1,0,0,3,1,0&smbb=1696737783,17317759',
        name: 'Darlings Under the Sturgeon Moon'
      }
    }
    return {};
  } else {
    let objUrl = text.split(':');

    objUrl = objUrl[4].split('external_url');
    objUrl = objUrl[0];
    objUrl = objUrl.substring(0, objUrl.length - 3);

    objUrl = objUrl.split('/');
    objUrl = objUrl[5];
    // objUrl = objUrl[5].split("&");
    // objUrl = `${objUrl[0]}&${objUrl[1]}&${objUrl[2]}&${objUrl[3]}`;
    // objUrl = `https://${objUrl}`;
    // console.log("objUrl", objUrl);
    let name = text.split(':');
    name = name[14];
    name = name.split('"');
    name = name[1];

    return {
      url: objUrl,
      name,
    };
  }
};


export const getTokenDetails = (tokenUri) => {

  let text = tokenUri;
  // console.log(text  )
  if (!text) {
    return {};
  } else {

    //------------------ song url
    // let objUrl = text.split(':');

    // objUrl = objUrl[4].split('external_url');
    // objUrl = objUrl[0];
    // objUrl = objUrl.substring(0, objUrl.length - 3);

    // objUrl = objUrl.split('/');
    // objUrl = objUrl[5];

    // // ------------ song Details
    // let obj = text?.split('"')
    // obj = obj[4]?.split('?')

    // const objDetails = obj[1]?.split('&');

    // const dj = objDetails[0].split('=');
    // const hsl = objDetails[1].split('=');
    // const len = objDetails[2].split('=');
    // const records = objDetails[3].split('=');


    // // ----------------- song name
    // let name = text
    // // name = name[14];
    // // name = name.split('"');
    // // name = name[1];
    // console.log(name)



    let details = text.split('{')

    let Orientation = details[2].split(':')
    Orientation = Orientation[2].split('"')
    Orientation = Orientation[1]
    //----------------------------------------

    let cycleLength = details[3].split(':')
    cycleLength = cycleLength[2].split('"')
    cycleLength = cycleLength[1]

    //----------------------------------------
    let entropy = details[5].split(':')
    entropy = entropy[2].split('"')
    entropy = entropy[1]

    //----------------------------------------
    let hsl = details[4].split(':')
    hsl = hsl[2].split('"')
    hsl = hsl[1]

    //----------------------------------------

    let records = details[6].split(':')
    records = records[2].split('"')
    records = records[1]

    //----------------------------------------
    let name = details[1].split(':')
    name = name[2].split('"')
    name = name[1]

    // ---------------------

    let objUrl = text.split('animation_url');
    objUrl = objUrl[1].split('"');
    objUrl = objUrl[2];

    return {
      cycleLength,
      hsl,
      Orientation,
      records,
      entropy,
      songName: name,
      songUrl: objUrl,
    };
  }
}