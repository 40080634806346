import React, { useContext, useState } from "react";

const MainContext = React.createContext();

export const MainContextProvider = (props) => {

  const genre1 = Math.floor(Math.random() * 8);
  var genre2 = Math.floor(Math.random() * 8);
  while(genre2 == genre1) {
    genre2 = Math.floor(Math.random() * 8);
  }
  var genre3 = Math.floor(Math.random() * 8);
  while (genre3 == genre2 || genre3 == genre1) {
    genre3 = Math.floor(Math.random() * 8);
  }

  const id1 = Math.floor(Math.random() * 360);
  var id2 = Math.floor(Math.random() * 360);
  while(id1 == id2) {
    id2 = Math.floor(Math.random() * 360);
  }
  var id3 = Math.floor(Math.random() * 360);
  while (id3 == id2 || id3 == id1) {
    id3 = Math.floor(Math.random() * 360);
  }
  const url1 = `tonekingzwithleads.html?genre=${genre1}&id=${id1}&len=3&bg=3`
  const url2 = `tonekingzwithleads.html?genre=${genre2}&id=${id2}&len=3&bg=3`
  const url3 = `tonekingzwithleads.html?genre=${genre3}&id=${id3}&len=3&bg=3`
  const urls = [new Map([["animation_url", url1]]), new Map([["animation_url", url2]]), new Map([["animation_url", url3]])]
  const [getHolderUrl, setHolderUrl] = useState({
    djUrls: urls,
    mainUrl: url1,
    thumbNails:[],    
    initializing: true
  });

  const [getMintedStaticSongs, setMintedStaticSongs] = useState({
    djUrls: [],
  });

  const [getFocusDetails, setFocusDetails] = useState({
    focusUrl: null,
    focusDJ: null,
    isStatic: null,
    gibberish: null
  });

  const [getStaticSongs, setStaticSongs] = useState({songs: []})
  const [select, setSelect] = useState(undefined);
  const [getGibberish, setGibberish] = useState(undefined);
  const [getWalletConnected, setWalletConnected] = useState({
    connect: false,
    isStatic: false,
    ss: [],
    sr: [],
    st: [],
    sgnr: [],
    smbb: [],
    gOver: -1,
    genre: -1,
    id: -1,
    len: -1,
    bg: -1,
    name: "",
  });

  const [account, setAccount] = useState(undefined);

    /*
    name: null,
    genre: null,
    id: 0,
    len: 0,
    bg: 0,
    ss: null,
    sgnr: null,
    sr: null,
    st: null,
    smbb: null
  })*/

  return (
    <MainContext.Provider value={{ holderUrls: [getHolderUrl, setHolderUrl], 
      focus: [getFocusDetails, setFocusDetails],
      staticSongs: [getStaticSongs, setStaticSongs],
      selected: [select, setSelect],
      gibberish: [getGibberish, setGibberish],
      mintedStaticSongs: [getMintedStaticSongs, setMintedStaticSongs],
      walletConnected: [getWalletConnected, setWalletConnected] ,
      walletAccount:[account, setAccount]
      }}>
      {props.children}
    </MainContext.Provider>

  );
};
export const useMainContext = () => {
  return useContext(MainContext);
};
