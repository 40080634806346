import CloseIcon from '@mui/icons-material/Close';
import { Divider, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import * as React from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 550,
  bgcolor: '#fff',
  boxShadow: '4px 4px 8px #847c7c',
  borderRadius: '12px',
  outline:'none'
};

const ModalObject = {
  hsl: 'HSL describes the family of colors used in the artwork based on the provided seed in the HSL color wheel',

  'Cycle Length': `Cycle Length will determine how much stamina your 0xDJ has during each track.
  Some 0xDJs prefer shorter quick songs that will get their fans just hyped enough before moving onto the next. On the other hand some 0xDjs prefer to mix longer songs to allow for Musickers to ride the wave for just that much longer`,

  'Total number of Records': 'Every 0xDJ is endowed with this special trait. A record lets a collector mint the song that is playing into its own separate NFT, called 0xsong. Once all of a 0xDJs records are used, no more songs can be minted.',

  'records Used': 'The number of records that have been used up to mint 0xsongs',

  orientation: 'Orientation is the trait that shows which direction a 0xDJ faces, and also reflects the journey we are taking together in this project. Often times we look back (Southpaw) in time in order to create new music for the future ahead of us (Dexter). Vanward orientation pushes us to remember to innovate and create and not just follow the rules we are so routinely caught up in.',

  Entropy: 'Entropy is one of the great forces in the universe, and the natural state of every system is chaos.All 0xDJ types are chaotic, but those with high entropy will have a greater tendency towards disorder, and those with low entropy will have slightly more order. The 0xDJs reflect the unpredictability of the universe.'
}

export default function TraitDetails({ handleClose, open, selected }) {
  return (
    <div>
      <Modal
        // hideBackdrop={true}
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        sx={{ bgcolor: '#fdfbfb17', boxShadow: 0 }}>
        <Box sx={style}>
          <Box sx={{ display: 'flex', px: 24, py: 10, justifyContent: 'space-between' }}>
            <Typography variant='h6' sx={{ fontWeight: 'bold', mt: 20, fontSize: '20px !important', textTransform: 'capitalize' }}>
              {selected}
            </Typography>
            <IconButton onClick={handleClose} sx={{ color: '#000' }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />
          <Box sx={{ p: 18, display: 'flex', justifyContent: 'center', alignItems: 'center', pt: 10 }}>
            <Typography variant='h6' sx={{ mt: 10 }}>
              {ModalObject[`${selected}`]}
            </Typography>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
