// ----------------------------------------------------------------------

function pxToRem(value) {
   return `${value / 16}rem`;
}

function responsiveFontSizes({ sm, md, lg, }) {
   return {
      '@media (min-width:600px)': {
         fontSize: pxToRem(sm),
      },
      '@media (min-width:900px)': {
         fontSize: pxToRem(md),
      },
      '@media (min-width:1200px)': {
         fontSize: pxToRem(lg),
      },
   };
}

const FONT_PRIMARY = 'Red Hat Display, sans-serif';

const typography = {
   fontFamily: FONT_PRIMARY,
   fontWeightRegular: 400,
   fontWeightMedium: 600,
   fontWeightBold: 700,
   h1: {
      fontWeight: 400,
      lineHeight: 80 / 64,
      fontSize: pxToRem(54),
      fontFamily: "Betatron",
      ...responsiveFontSizes({
         sm: 54,
         md: 64,
         lg: 64,
      }),
   },
   h2: {
      fontWeight: 700,
      lineHeight: 64 / 48,
      fontFamily:'CA Saygon Text',
      fontSize: pxToRem(32),
      ...responsiveFontSizes({
         sm: 40,
         md: 44,
         lg: 48,
      }),
   },
   h3: {
      fontWeight: 400,
      lineHeight: 1.5,
      fontSize: 36,
      fontFamily: "Betatron",
      ...responsiveFontSizes({
         sm: 36,
         md: 36,
         lg: 36,
      }),
   },
   h4: {
      fontWeight: 400,
      lineHeight: 1.5,
      fontFamily:'CA Saygon Text',
      fontSize: pxToRem(20),
      ...responsiveFontSizes({
         sm: 20,
         md: 24,
         lg: 24,
      }),
   },
   h5: {
      fontWeight: 400,
      lineHeight: 1.5,
      fontSize: pxToRem(20.3088),
      fontFamily: "Betatron",
      ...responsiveFontSizes({
         sm: 16,
         md: 20,
         lg: 20,
      }),
   },
   h6: {
      fontWeight: 400,
      fontFamily:'CA Saygon Text',
      lineHeight: 28 / 18,
      fontSize: pxToRem(14.6675),
      ...responsiveFontSizes({
         sm: 16,
         md: 16,
         lg: 16,
      }),
   },
   subtitle1: {
      fontWeight: 400,
      lineHeight: 1.5,
      fontSize: pxToRem(16),
      ...responsiveFontSizes({
         sm: 18,
         md: 20,
         lg: 24,
      }),
   },
   subtitle2: {
      fontWeight: 600,
      lineHeight: 22 / 14,
      fontSize: pxToRem(14),
   },
   body1: {
      lineHeight: 1.5,
      fontSize: pxToRem(16),
   },
   body2: {
      lineHeight: 22 / 14,
      fontSize: pxToRem(14),
   },
   caption: {
      lineHeight: 1.5,
      fontSize: pxToRem(12),
   },
   overline: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(12),
      letterSpacing: 1.1,
      textTransform: 'uppercase',
   },
   button: {
      fontWeight: 700,
      lineHeight: 24 / 14,
      fontSize: pxToRem(14),
      textTransform: 'capitalize',
   },
};

export default typography;
