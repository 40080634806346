import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import MButton from '../Components/MButton';
import Slider from '../Components/Slider/Slider';

export default function Meet() {
  return (
    <Box>
      <Box sx={{ px: { xs: 16, md: 40, lg: 100 }, mt: 155, mb: 40 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Typography variant='h3'>Meet the 0xDJs</Typography>
          <MButton
            title='Buy on OpenSea'
            sx={{ mt: 0, display: { xs: 'none', md: 'block' } }}
            component='a'
            target='_blank'
            href='https://opensea.io/collection/0xmusic'
          />
        </Box>
      </Box>
      <Slider />
    </Box>
  );
}
