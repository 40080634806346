/* eslint-disable jsx-a11y/alt-text */
import { InputAdornment } from "@mui/material";
import { Box, Grid, IconButton, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React, { useState } from "react";
import { useQuerySingleNft } from "../hooks/useData";
import { useMainContext } from "../MainContext/MainContext";
import { textGradient } from "../style/style";
import HeadTable from "./HeadTable";
import MButton from "./MButton";
import TableAccordion from "./TableAccordion";

export default function Header() {
	const { holderUrls, walletAccount } = useMainContext();
	const [account] = walletAccount;

	const random = Math.floor(Math.random() * 777) + 1;
	const [inputValue, setInputValue] = useState();

	//   const [getRandomNumber, setGetRandomNumber] = useState(random);
	const [tokenId, setTokenId] = useState(random);
	const [isNft, setIsNft] = useState(false);

	const { isLoading, data: NFTDetails } = useQuerySingleNft({ tokenId, account });
	// console.log("🚀 ~ file: Header.jsx ~ line 23 ~ Header ~ NFTDetails", NFTDetails?.imageUrl)

	const [getHolderUrl, setHolderUrl] = holderUrls;
	const [changeImage, setChangeImage] = useState(0);

	const handleToken = (e) => {
		e.preventDefault()
		setTokenId(inputValue);
		setIsNft(true);
		// setGetRandomNumber(inputValue);
	};


	const handleChange = () => {
		const rand = (Math.random() * 777) | 0;
		setChangeImage(rand);
		setTokenId(rand);
		setInputValue(rand)
		// console.log(rand)
		setIsNft(false);
	};

	return (
		<Box>
			<Container
				maxWidth="lg"
				sx={{
					width: "100%",
					mt: { xs: 120, md: 180 },
					px: { xs: 16, md: 80, lg: 40 },
					mb: { xs: 260, md: 0 },
				}}
			>
				<Grid
					item
					container
					sx={{
						border: "2px solid gary",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Grid
						item
						xs={12}
						sm={10}
						md={6}
						lg={6}
						sx={{ mx: "auto", mt: { xs: -60, sm: 0 } }}
					>
						<Typography
							variant="h1"
							color="#414141"
							sx={{ fontSize: "48px !important" }}
						>
							777 NFTs, Infinite
							<br /> Possibilities
						</Typography>
						<Typography
							variant="h4"
							sx={{ mt: 40, ...textGradient, pr: { md: 26 }, maxWidth: 535 }}
						>
							Welcome to the cutting edge of music and NFTs: a billion songs in
							your wallet.
						</Typography>
						<MButton
							title="Buy on OpenSea"
							sx={{
								bgcolor: "#2993FF !important",
								color: "#fff",
								mr: { xs: 4, sm: 16 },
								fontSize: { xs: "13px", sm: "16px" },
							}}
							component="a"
							target="_blank"
							href="https://opensea.io/collection/0xmusic"
						/>
						<MButton
							title="Join our Discord"
							component="a"
							target="_blank"
							href="http://discord.gg/0xmusic"
							sx={{ fontSize: { xs: "13px", sm: "16px" } }}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
						lg={5.4}
						sx={{ textAlign: "center", mt: { xs: 50, md: 0 } }}
					>
						<Box sx={{ maxWidth: 424, mx: "auto", width: "100%" }}>
							<Box component="form"
								noValidate
								onSubmit={handleToken}
								autoComplete="off" sx={{ display: "flex", px: 8, justifyContent: 'space-between', alignItems: 'center', }}>
								<TextField
									type="number"
									variant="standard"
									InputProps={{
										startAdornment: <InputAdornment position="start">Token Id: </InputAdornment>,
									}}
									id="standard-start-adornment"
									defaultValue={tokenId}
									value={inputValue}
									onChange={(e) => setInputValue(e.target.value)}
									sx={{
										// borderRadius: '10px',
										"& p": {
											fontSize: 17,
											color: 'inherit'
										},
										"& input": {
											borderRadius: 0,
											width: 50,
											fontSize: 18
										},
										"& label": {
											color: "inherit",
											fontWeight: "bold",
											fontFamily: "inherit",
										},
										"& .MuiOutlinedInput-root": {
											"& fieldset": {
												border: "1.5px solid #2993FF !important",
												borderRadius: "8px",
											},
											"&.Mui-focused fieldset": {
												border: "2px solid #2993FF !important",
											},
										},
									}}
								/>
								<MButton
									type='submit'
									// onClick={handleToken}
									title="Find 0xDJ"
									sx={{
										mt: 0,
										width: 'fit-content',
										fontWeight: 400,
										fontSize: 16,
										borderRadius: "10px",
									}}
								/>
							</Box>

							<Box sx={{ height: 400, overflow: "none", mb: 6 }}>
								<object
									id="main2"
									data={
										tokenId < 777 &&
										NFTDetails?.imageUrl
									}
									width="100%"
									height="425px"
									style={{ overflow: 'hidden !important' }}
								/>
							</Box>
							<Box
								sx={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									pl: 10,
								}}
							>
								<Typography
									variant="h5"
									sx={{ textTransform: 'capitalize', fontSize: '24px !important', fontWeight: 'normal' }}
								>{tokenId < 777 && NFTDetails?.name}</Typography>
								<IconButton
									onClick={handleChange}
									sx={{ bgcolor: "#F4F4F4 !important" }}
								>
									<Box component="img" src="/assets/svg/squareIcon.svg" />
								</IconButton>
							</Box>
							<TableAccordion tokenId={tokenId} account={account} />

							<Box
								sx={{
									display: { xs: "block", md: "none" },
									mt: 100,
									...textGradient,
									px: { md: 26 },
									textAlign: "center",
								}}
							>
								<Typography variant="h6">
									You are the first and only person to hear these songs. Each
									one is unique, never to be repeated. Give this one a spin, or
									load another!
								</Typography>
							</Box>
						</Box>
					</Grid>
				</Grid>
				<Typography
					variant="h4"
					sx={{
						mt: 155,
						...textGradient,
						px: { md: 26 },
						textAlign: "center",
						display: { xs: "none", md: "block" },
					}}
				>
					You are the first and only person to hear these songs.
					<br /> Each one is unique, never to be repeated.
				</Typography>
			</Container>
		</Box>
	);
}
