export const textGradient = {
  background: "linear-gradient(270deg, #1DF1CA 0%, #2C6EF7 100%)",
  webkitBackgroundClip: "text",
  webkitTextFillColor: "transparent",
  backgroundClip: "text",
  textFillColor: "transparent",
};

export const cardBg = {
  border: "1px solid #F4F4F4",
  boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
};
