import { Box } from '@mui/material';
import { Route, Routes } from 'react-router';
import Layout from '../layout/index';
import LandingPage from '../pages/LandingPage';
import MasterPice from '../pages/MasterPice';

export default function AppRouters() {
  return (
    <Box>
      <Layout>
        <Routes>
          <Route path='/' element={<LandingPage />} />
          <Route path='/masterpiece' element={<MasterPice />} />
        </Routes>
      </Layout>
    </Box>
  );
}
