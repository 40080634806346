/* eslint-disable jsx-a11y/alt-text */
import { Icon } from "@iconify/react";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { useMutationSong, useQuerySongs } from "../hooks/useAudio";
// import { getObjUrl } from "../utils";
import { styled } from "@mui/material/styles";
import { useRef, useState } from "react";
import { TwitterShareButton } from "react-share";
import ReactMusicPlayer from "../Components/ReactAudioPlayer";
import Scrollbar from "../Components/Scrollbar";
import { useMainContext } from "../MainContext/MainContext";
import { cardBg } from "../style/style";
import TwitterShare from "../Components/Modal/TwitterShare";
// import { Tweet } from "../src"

// createStyles

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    background: #000;
    color: #fff;
    font-size: 16px;
    border-radius: 10px;
    padding: 10px;
  }
  & .MuiTooltip-arrow {
    color: #000;
  }
`;

export default function AudioSection({ refresh }) {
  const ref = useRef();
  const { walletAccount } = useMainContext();
  const [account] = walletAccount;
  const { isLoading, data: songsArray = [] } = useQuerySongs(account);



  // console.log("🚀 ~ file: AudioSection.jsx ~ line 85 ~ Object.keys ~ song_id", songsArray)
  // 

  const { isLoading: isLoadingSong, mutate: audioSong } =
    useMutationSong(account);
  const [songName, setSongName] = useState("new Song");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Typography variant="h3">Side B: Community Creations</Typography>

      <Typography variant="h6" sx={{ mt: 24 }}>
        Hit Play to listen to the community creations below. Each of these was
        created using the MIDI file of an 0xDJ’s song, and collectors used their
        creativity to remix instruments, beats, to make the piece their own.{" "}
        <br />
        Vote to include your favorite Community Creations in the album by
        clicking on the headphones.
      </Typography>
      {/* card for votes */}
      <Box sx={{ height: 580, mt: 60, pb: 100, overflow: 'auto' }}>
        <Scrollbar >
          <Grid
            item
            container
            xs={12}
            spacing={10}
            sx={{ mt: 10, justifyContent: "flex-start", bgcolor: "#fff" }}
          >
            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <img src="/LoadingSpinner.gif" width="180" height="180" />

                {/* <CircularProgress /> */}
              </Box>
            ) : (
              Object.keys(songsArray)?.reverse()?.map((song_id, idx) => {
                const song = songsArray[song_id][0];
                const count = songsArray[song_id].length - 1;

                const hasUserVoted = account
                  ? songsArray[song_id].some(
                    (item) => item.user_wallet === account
                  )
                  : false;

                // console.log({ song, count, hasUserVoted, account });
                
                return (
                  <Grid
                    key={`song___${idx}`}
                    item
                    xs={12}
                    sm={12}
                    md={3.8}
                    sx={{
                      textAlign: "center",
                      mt: { xs: 50, md: 30 },
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: 300,
                        width: "100%",
                        height: "100%",
                        mx: "auto",
                        ...cardBg,
                        overflow: "hidden",
                        mb: 20,
                        textAlign: "left",
                        borderRadius: "24px 24px 0px 0px",
                        cursor: "pointer",
                      }}
                    >
                      <Box sx={{ height: 50 }}>
                        <ReactMusicPlayer src={song.url} playerRef={ref} />
                      </Box>
                      <Box sx={{ p: 16, pb: 0, bgcolor: "#fff" }}>
                        <Box
                          sx={{
                            "button:disabled, button[disabled]": {
                              border: "none",
                            },
                            display: "flex",
                            justifyContent: "space-between",
                            "svg:hover": {
                              color: "#000",
                            },
                          }}
                        >
                          <Box>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold" }}
                            >
                              {song.name}
                            </Typography>
                            {
                              //   <Typography variant="h6" sx={{ mt: 5 }}>
                              //   {" "}
                              //   {song.created_at}
                              // </Typography>
                            }
                          </Box>
                          <Box
                            sx={{
                              mt: -5,
                            }}
                          >
                            <StyledTooltip
                              title={hasUserVoted ? "Unvote" : "Vote"}
                              arrow={true}
                              placement="top"
                              sx={{
                                "& .MuiTooltip-popper": {
                                  bgcolor: "red",
                                  color: "#fff",
                                },
                              }}
                            >
                              <IconButton

                                aria-label="contained"
                                onClick={async () => {
                                  if (!account) await refresh();

                                  if (account) {
                                    audioSong({
                                      song,
                                      account,
                                      hasUserVoted,
                                    });
                                    if (!hasUserVoted) {
                                      handleOpen();
                                      setSongName(song.name);
                                    }
                                  }
                                }}
                              >
                                <Icon
                                  icon={
                                    hasUserVoted
                                      ? !account
                                        ? "fluent:headphones-48-regular"
                                        : "fluent:headphones-20-filled"
                                      : "fluent:headphones-48-regular"
                                  }
                                  width={22}
                                  color={
                                    hasUserVoted
                                      ? !account
                                        ? "#939694"
                                        : "#000"
                                      : "#939694"
                                  }
                                />
                              </IconButton>
                            </StyledTooltip>
                            <TwitterShare
                              open={open}
                              songName={songName}
                              handleClose={handleClose}
                            />
                            <Typography
                              sx={{
                                textAlign: "center",
                                color: hasUserVoted ? "#000" : "#939694",
                                fontWeight: hasUserVoted ? 'bold' : 'normal'
                              }}
                            >
                              {count}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                );
              })
            )}
          </Grid>
          {/* <Tweet
                              tweetId={`1436165107675975685`}
                              renderError={(_err) =>
                                "Could not load tweet! ...Your custom component here"
                              }
                            /> */}
        </Scrollbar>
      </Box>
    </>
  );
}
