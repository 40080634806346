import React, { useState } from "react";

import { useMainContext } from "../MainContext/MainContext";
import { Modal, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import parentContract from '../contracts/Oxmusic.json'
import contract from '../contracts/OxmusicStaticSong.json'

import {ethers} from 'ethers';
import Spinner from 'react-bootstrap/Spinner';


const contractAddress = process.env.REACT_APP_STATIC_CONTRACT_ADDRESS
const parentContractAddress = process.env.REACT_APP_CONTRACT_ADDRESS


const abi = contract.abi;
const parentAbi = parentContract.abi;
const GAS_LIMIT = 390000 
const EST_BUFFER = 25000


const SongSection = () => {

  const {holderUrls, focus, staticSongs, selected, walletConnected} = useMainContext();

  const [getFocusDetails, setFocusDetails] = focus;
  const [getStaticSongs, setStaticSongs] = staticSongs;
  const [select, setSelect] = selected
  const [getWalletConnected, setWalletConnected] = walletConnected;

  
  const [show, setShow] = useState(false);
  const [songName, setSongName] = useState("");
  const [staticUrl, setStaticUrl] = useState("")
  const [getss, setss] = useState([])
  const [getsr, setsr] = useState([])
  const [getst, setst] = useState([])
  const [getsgnr, setsgnr] = useState([])
  const [getsmbb, setsmbb] = useState([])
  const [gOver, setgOver] = useState(-1)
  const [getgenre, setgenre] = useState(-1)
  const [getId, setId] = useState(-1)
  const [getLen, setLen] = useState(-1)
  const [getBg, setBg] = useState(-1)
  const [getStaticSongsUsed, setStaticSongsUsed] = useState(-1)

  const [tokenId, setTokenId] = useState(-1)
  const [currentIndex, setCurrentIndex] = useState(-1)
  const [invalidForMint, setInvalidForMint] = useState(false);
  const [mintError, setMintError] = useState(false);
  const [mintErrorText, setMintErrorText] = useState("");
  const [loading, setLoading] = useState(false);


  const mintNftHandler = async (name, args, tokenId) => {
    const {
      ethereum
    } = window;    

    try {
      if (ethereum){
        if (/[^0-9a-zA-Z ']/.test(name)) {
          setMintErrorText('Name must only have numbers and alphabets')
          setMintError(true)
          return
          // It has an invalid character
        }
  
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, abi, signer);
        const parentContract = new ethers.Contract(parentContractAddress, parentAbi, signer);

        if (parseInt(await nftContract.nameMapping(name)) > 0) {
          setMintErrorText('Song name taken')
          setMintError(true)
          return
        }

        setMintErrorText('')
        setMintError(false)

        console.log("Initialize payment");

        let estimate
        function estimateGas(gasAmount) {
          estimate = parseInt(gasAmount, 10) + EST_BUFFER
          console.log('got estimate of ' + estimate)
          console.log('limit will be ' + estimate ?? GAS_LIMIT)
        }

        await nftContract.estimateGas.mint(tokenId, args, name).then(estimateGas);
        let nftTxn = await nftContract.mint(tokenId, args, name, {gasLimit: estimate});

        if (nftTxn){
          console.log("Minting ... please wait");
          setLoading(true);
          await nftTxn.wait();
          console.log(`Minted, see transaction: https://etherscan.io/tx/${nftTxn.hash}`);
          const masterName =  await parentContract.getMasterFromTokenId(tokenId)
          const songs = getStaticSongs.songs.slice()
          var mintedSong = songs[currentIndex]
          mintedSong.disableMint = true
          setStaticSongs({songs: songs})
          setCurrentIndex(-1)
          setLoading(false);
          setShow(false);
          setWalletConnected({
            connect: true,
            tokenId: tokenId,
            isStatic: true,
            ss: getss,
            sr: getsr,
            st: getst,
            sgnr: getsgnr,
            smbb: getsmbb,
            gOver: gOver,
            genre: getgenre,
            id: getId,
            len: getLen,
            bg: getBg,
            name: songName,
            masterName: masterName,
            staticSongsUsed: getStaticSongsUsed
          })

          var repaintedSongs = getStaticSongs.songs.slice()
          for (var song of repaintedSongs){
            if (song.tokenId != tokenId) {
              continue
            }
            const staticSongsUsed = parseInt(await nftContract.tokenIdToStaticSongsMinted(tokenId))
            const details = await parentContract.getImageDetails(tokenId)
            const records = parseInt(details[3], 10)
            song.staticSongsUsed = staticSongsUsed
            if (records == staticSongsUsed) {
              song.disableMint = true
            }
          }

          setStaticSongs({songs: repaintedSongs})
//          connectWalletHandler()
        } else {
          console.log("minting not available yet")
        }
      } else {
        console.log("ethereum object does not exist");
      }
    } catch (err){
      console.error(err);
      setMintErrorText(err.message)
      setMintError(true)
    }

  }


  const handleClose = () => {
    setShow(false);
    setSongName("");
    setStaticUrl("");
    setTokenId(-1);
    setCurrentIndex(-1)
  }

  const handleShow = (url, tokenId, index, genre, id, len, bg,ss,
    sgnr, sr, st, smbb, staticSongsUsed, gover) => {
    setStaticUrl(url)
    setShow(true)
    setTokenId(tokenId)
    setCurrentIndex(index)
    setgenre(genre)
    setgOver(gover)
    setId(id)
    setLen(len)
    setBg(bg)
    setss(ss)
    setsgnr(sgnr)
    setsr(sr)
    setst(st)
    setsmbb(smbb)
    setStaticSongsUsed(staticSongsUsed)
    setMintError(false)
  }

  const handleSubmit = () => {
    if (!songName){
      setInvalidForMint(true)
      return
    } else {
      setInvalidForMint(false)
    }
    mintNftHandler(songName, staticUrl, tokenId)
    console.warn('ok song name is ' + songName)
  }
 

  /*
  useLayoutEffect(() => {
    if (getFocusDetails.focusUrl){
      try {
        console.log('doing this from here')
        loadDJ(getFocusDetails.focusDJ)
      } catch(err){
        console.error(err)
      }
    }
    }, [getStaticSongs.songs]) */

    const getClassName = (index) => {
      if(select == index) {
        return "song-row"
      }

    }
  
    const addStaticSong = (genre, id, len, bg, ss, sgnr, sr, st, smbb, index, songName, midiUri, staticSongsUsed, gover, tokenId) => {
  
      const imageDetailsWithStatic = new Map([
      ['dj', genre],
      ['hsl', id], 
      ['len', len], 
      ['records', bg],
      ['static', 1],
      ['ss', ss],
      ['sgnr', sgnr],
      ['sr', sr],
      ['st', st],
      ['smbb', smbb],
      ['song', '0xSong '+index],
      ['name', songName],
      ['song_name', songName],
      ['gover', gover],
      ['static_songs_used', staticSongsUsed],
      ['tokenId', tokenId]
    ])
  
    setFocusDetails({
        focusUrl: `tonekingzwithlead.html?genre=${genre}&id=${id}&len=${len}&bg=${bg}`,
        focusDJ: imageDetailsWithStatic,
        isStatic: false,
        midiUri: midiUri,
        records: bg,
        staticSongsUsed: staticSongsUsed
    })
    setSelect(index)
  
  }


  

  return (
    <div className="static-songs">

    <div style={{ maxHeight:'300px', overflow:'auto' }}>

    <table>
      <thead>
        {getStaticSongs.songs &&
    <tr key="row_header" className='row_header'>
      <td className="play-button"><h5></h5></td>
      <td className="song-name" ><h5 style={{marginTop:10}}>0xSongs</h5></td>
      <td class='midi-column'><h5 style={{marginTop:10}}>MIDI Files</h5></td>
      <td class="mint-nft"><h5 style={{marginTop:10}}>Mint As NFT</h5></td>
    </tr>
    }
    </thead>

    <tbody>
    {
      getStaticSongs.songs &&
      getStaticSongs.songs.map((item, index) => 
        <tr key={"row_" + (getStaticSongs.songs.length - index).toString()} className={getClassName(getStaticSongs.songs.length - index)}>
         <td className="play-button"><span  onClick={() => addStaticSong(item.genre,item.id, item.len,item.bg,item.ss,
        item.sgnr, item.sr, item.st, item.smbb, getStaticSongs.songs.length - index, item.name, item.midiUri, item.staticSongsUsed, item.gover, item.tokenId)}><img className='inline-svg' src='playnext2.svg'/></span></td>
        <td className="song-name"><span  onClick={() => addStaticSong(item.genre,item.id, item.len,item.bg,item.ss,
        item.sgnr, item.sr, item.st, item.smbb, getStaticSongs.songs.length - index, item.name, item.midiUri, item.staticSongsUsed, item.gover, item.tokenId)}>{item.name} 0xSong {getStaticSongs.songs.length - index}</span></td>
        <td className='midi-column'>
          {<a download={item.name+ " 0xSong " + (getStaticSongs.songs.length - index)} href={item.midiUri}>
        <img className='midi-icon' src='midilogo.svg'></img>
        </a>}
        </td>
        <td className="mint-nft"><button className="button" onClick={() => handleShow(item.staticUrl, item.tokenId, index, item.genre,item.id, item.len,item.bg,item.ss,
        item.sgnr, item.sr, item.st, item.smbb, item.staticSongsUsed, item.gover)} disabled={item.disableMint}>
        MINT
        </button> 
        </td>
        <td></td>
      </tr>
      )
    }
       </tbody>
   </table>
   </div>
   <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>MINT STATIC SONG</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>SONG NAME</Form.Label>
              <Form.Control
                type="email"
                placeholder="Dancing Trees"
                onChange={(e) => setSongName(e.target.value)}
                autoFocus
              />
            </Form.Group>
            <div>
              {invalidForMint && <p className="nomint py-2">Please Enter a song name</p>}
              {mintError && <p className="nomint py-2">{mintErrorText}</p>}
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        <a className="button" onClick={() => handleSubmit()}>
          MINT </a>
          { loading &&
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        }
        </Modal.Footer>
      </Modal>
   </div>
       
  );
};

export default SongSection;
