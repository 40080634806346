import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import NewsCard from '../Components/Cards/NewsCard';

const NEWS_DATA = [
  {
    title: 'The Vision',
    desc: 'Our vision is simple, but unique. We’re pioneering a new way to experience music',
    image: '/assets/svg/420.png',
    link: 'https://medium.com/@0xmusic.art/the-vision-160e0a15e438',
  },
  {
    title: 'Drip',
    desc: 'On 5 April 2016, “One Dance” by Drake (feat. Wizkid and Kyla) was released. To celebrate its influence on the 0xmusic ...',
    image: 'https://i.pinimg.com/originals/12/76/ed/1276edc06678ebc7c5b651eee42d8998.png',
    link: 'https://medium.com/@0xmusic.art/drip-3557fa77cb0b',
  },
  {
    title: 'Bach',
    desc: 'On 31 March 1685, John Sebastian Bach was born. To celebrate his influence on the 0xmusic project, all 0xDJs will ...',
    image: 'https://www.dallassymphony.org/wp-content/uploads/2022/04/Johann-Sebastian-Bach.jpg',
    link: 'https://medium.com/@0xmusic.art/bach-a5ce24f0b93f',
  },

  {
    title: 'Serena',
    desc: 'On 15 March 2004, American Dream Pop band Blonde Redhead released Misery is a Butterfly. 0xDJ Serena was heavily ...',
    image:
      'https://t2.genius.com/unsafe/327x327/https%3A%2F%2Fimages.genius.com%2F5b6263878336c83efa16f11b663e17ca.920x920x1.jpg',
    link: 'https://medium.com/@0xmusic.art/serena-f58ede7faa07',
  },
  {
    title: 'Handel',
    desc: 'On February 23rd, 1685, George Frideric Handel was born. To honor him, on every February 23 beginning in 2022, ...',
    image: 'https://www.thefamouspeople.com/profiles/images/george-frideric-handel-4.jpg',
    link: 'https://medium.com/@0xmusic.art/handel-22e46173c8a9',
  },

  {
    title: 'Part I',
    desc: '0xmusic in a Nutshell — The first project by 0xmusic, is a ground breaking generative audio visual project based ...',
    image: '/assets/svg/Part1.png',
    link: 'https://medium.com/@0xmusic.art/infinite-song-factory-part-i-c89a3ed405c3',
  },
  {
    title: 'Part II',
    image: '/assets/svg/part2.png',
    desc: 'My thoughts on the generative music space — Though I officially started 0xmusic sometime in 2021, I realized ...',
    link: 'https://medium.com/@0xmusic.art/infinite-song-factory-part-2-818e913c8c28',
  },
];

export default function News() {
  const [arrayLength, setArrayLength] = useState(4);

  const handleLoadMore = () => {
    setArrayLength(NEWS_DATA.length);
  };

  return (
    <Box
      sx={{
        maxWidth: 840,

        mt: { xs: 200, md: 110 },
        mx: 'auto',
        px: 16,
      }}>
      <Typography variant='h3'>News</Typography>

      {/* Social links */}
      <Grid item container xs={12} sx={{ mt: 40, justifyContent: 'space-between' }}>
        {NEWS_DATA.map((data, idx) => {
          if (idx < arrayLength) {
            return (
              <Grid
                key={data.title}
                item
                xs={12}
                md={5.8}
                sx={{
                  display: 'flex',
                  mt: 22,
                  p: 16,
                  border: '1px solid #F4F4F4',
                  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
                  borderRadius: '8px',
                }}>
                <NewsCard card={data} idx={idx} />
              </Grid>
            );
          }
        })}
      </Grid>
      <Box sx={{ textAlign: 'center', display: arrayLength === 4 ? 'block' : 'none' }}>
        <Button
          onClick={handleLoadMore}
          sx={{
            fontSize: 16,
            borderRadius: 25,
            boxShadow: 23,
            px: 24,
            py: 6,
            fontWeight: 'normal',
            color: '#414141',
            mt: 40,
          }}>
          <AddIcon sx={{ mr: 5 }} /> Load more
        </Button>
      </Box>
    </Box>
  );
}
