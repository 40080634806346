import { BrowserRouter, HashRouter } from "react-router-dom";
import AppRouters from "./routes";
import ThemeConfig from "./theme/index";
import { QueryClientProvider, QueryClient } from "react-query";

const queryClient =new QueryClient();

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <HashRouter>
          <ThemeConfig>
            <AppRouters />
          </ThemeConfig>
        </HashRouter>
      </QueryClientProvider>
    </>
  );
}

export default App;
