import { Box } from '@mui/material';
import Footer from './Footer';
import Navbar from './Navbar';

function index({ children }) {
  return (
    <Box>
      <Navbar />
      <Box>{children}</Box>
      <Footer />
    </Box>
  );
}

export default index;
