/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
import { Icon } from "@iconify/react";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useMoralis, useNFTBalances } from "react-moralis";
import Scrollbar from "../Components/Scrollbar";
import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import { useMutationVote, useQueryNfts } from "../hooks/useData";
import { useMainContext } from "../MainContext/MainContext";
import { getObjUrl } from "../utils";
// import SectionBMasterpice from "../section/SectionBMasterpice";
import { styled } from "@mui/material/styles";
import TwitterShare from "../Components/Modal/TwitterShare";
import contract from "../contracts/Oxmusic.json";
import staticSongContract from "../contracts/OxmusicStaticSong.json";
import SectionB from "../section/AudioSection";
import LeaderBoard from "../section/LeaderBoard";
import { cardBg } from "../style/style";
import { filteredVoted } from "./../utils/index";
import { Button } from "@mui/material";

// const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
// const staticSongAddress = process.env.REACT_APP_STATIC_CONTRACT_ADDRESS;
// const abi = contract.abi;
// const staticSongAbi = staticSongContract.abi;
// const chainId = process.env.REACT_APP_CHAIN_ID;

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    background: #000;
    color: #fff;
    font-size: 16px;
    border-radius: 10px;
    padding: 10px;
  }
  & .MuiTooltip-arrow {
    color: #000;
  }
`;

export default function MasterPice() {
  // const { walletAccount } = useMainContext();
  // const [account] = walletAccount;

  // let counter = 10

  const handleLoadCards = () => {
    setCardToShow(cardToShow + 10);
  };

  const { authenticate, isAuthenticated, user } = useMoralis();
  const { getNFTBalances, data, error, isLoading, isFetching } =
    useNFTBalances();

  const {
    holderUrls,
    focus,
    walletConnected,
    walletAccount,
    mintedStaticSongs,
  } = useMainContext();

  const [getHolderUrl, setHolderUrl] = holderUrls;
  const [getFocusDetails, setFocusDetails] = focus;
  const [getWalletConnected, setWalletConnected] = walletConnected;
  const [getMintedStaticSongs, setMintedStaticSongs] = mintedStaticSongs;
  const [account, setAccount] = walletAccount;

  const phrases = ["0xmusic", "generative music"];

  const [currentAccount, setCurrentAccount] = useState(null);
  const [currentAccountShort, setCurrentAccountShort] =
    useState("Connect Wallet");

  function refresh() {
    setWalletConnected({
      connect: true,
      isStatic: false,
      ss: [],
      sr: [],
      st: [],
      sgnr: [],
      smbb: [],
      gOver: -1,
      genre: -1,
      id: -1,
      len: -1,
      bg: -1,
      name: "",
    });
  }

  const disconnectWallet = () => {
    setCurrentAccount();
    setAccount();
    setCurrentAccountShort("Connect Wallet");
    setWalletConnected({
      connect: false,
      isStatic: false,
      ss: [],
      sr: [],
      st: [],
      sgnr: [],
      smbb: [],
      gOver: -1,
      genre: -1,
      id: -1,
      len: -1,
      bg: -1,
      name: "",
    });
  };

  const connectWalletHandler = async () => {
    const { ethereum } = window;

    try {
      if (!getWalletConnected.connect) {
        return;
      } else {
        setWalletConnected({
          connect: false,
          isStatic: false,
          ss: [],
          sr: [],
          st: [],
          sgnr: [],
          smbb: [],
          gOver: -1,
          genre: -1,
          id: -1,
          len: -1,
          bg: -1,
          name: "",
        });
      }

      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      let account = accounts[0];
      setAccount(account);
      setCurrentAccount(account);
      setCurrentAccountShort(
        account.substring(0, 4) + "..." + account.substring(account.length - 4)
      );
    } catch (err) {
      setWalletConnected(false);
      setAccount();
      console.error(err);
    }
  };

  //checkWalletIsConnected()
  // useEffect(() => {
  //   connectWalletHandler();
  // }, []);

  // useEffect(() => {

  // }, []);

  const [cardToShow, setCardToShow] = useState(9);
  const { isLoading: isNFTLoading, data: NFTData } = useQueryNfts();
  const [songName, setSongName] = useState("new Song");

  const { countData, newArray: cardArray } = NFTData || {};

  const {
    isLoading: isLoadingUpVote,
    mutate: upVote,
    data: newData,
  } = useMutationVote();

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleLoadMore = () => {
    if (cardArray.length > cardToShow) {
      setCardToShow(cardToShow + 10);
    }
  }


  return (
    <>
    
      <Box sx={{ px: 24, mt: 150, maxWidth: 1000, mx: "auto" }}>
        <Typography variant="h3" sx={{ textTransform: "capitalize" }}>
          It all comes together
        </Typography>
        <Typography variant="h6" sx={{ mt: 24 }}>
          The final phase in the 0xmusic party is where the community unites to
          create a masterpiece: The 0xmusic Genesis album. The 0xmusic community
          owns the responsibility of both creating and curating this paragon.{" "}
        </Typography>
        <br />
        <Typography variant="h6">
          <Typography component="span" sx={{ fontWeight: 900, fontSize: 18 }}>
            Side A
          </Typography>{" "}
          of the 0xmusic Genesis album will feature 0xsongs that have been
          discovered and minted by collectors. <br />
          <Typography component="span" sx={{ fontWeight: 900, fontSize: 18 }}>
            Side B{" "}
          </Typography>
          of the album will feature Community Creations composed by collectors
          using MIDI files from their 0xDJs.
        </Typography>
        <Typography variant="h6" sx={{ mt: 24 }}>
          Both holders and non-holders will have the power to curate the album
          by voting on their favorite 0xsongs and Community Creations below.
          Those pieces with the highest number of votes will be selected and
          included in the Genesis album, which will be released as an ERC 1155
          token.
          <br />
          <br />
          <b>
            {" "}
            Every collector whos 0xsong or creation is included in the album
            shall receive a surprise. Additionally, every person that votes will automatically be entered into drawings for surprise rewards.
          </b>
        </Typography>

        <Typography variant="h3" sx={{ mt: 100 }}>
          Side A: 0xsongs
        </Typography>
        <Typography variant="h6" sx={{ mt: 24 }}>
          Hit Play to listen to 0xsongs that were discovered, named, and minted
          by 0xmusic collectors <br />
          Vote to include your favorite 0xsongs in the album by clicking on the
          headphones.
        </Typography>

        {/* card for votes */}

        <Box sx={{ height: 1000, pb: 100, mt: 60, overflow: 'auto' }}>
          <Scrollbar>
            <Grid
              item
              container
              xs={12}
              spacing={10}
              sx={{ mt: 0, justifyContent: "flex-start", bgcolor: "#fff" }}
            >
              {/* <Typography></Typography> */}
              {isNFTLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <img src="/LoadingSpinner.gif" width="180" height="180" />
                  {/* <CircularProgress /> */}
                </Box>
              ) : (
                cardArray?.map((card, idx) => {
                  const { name, url } = getObjUrl(card?.token_uri, card?.token_id);

                  const count = countData[card.token_id]?.length || 0;

                  const hasUserVoted =
                    countData[card.token_id] && account
                      ? filteredVoted(
                        countData[card.token_id],
                        account,
                        card.token_id
                      )
                      : false;

                  if (idx < cardToShow) {
                    return (
                      <Grid
                        key={`card___${idx}`}
                        item
                        xs={12}
                        sm={12}
                        md={3.8}
                        sx={{
                          textAlign: "center",
                          mt: { xs: 50, md: 30 },
                        }}
                      >
                        <Box
                          sx={{
                            maxWidth: 310,
                            width: "100%",
                            height: "100%",
                            ...cardBg,
                            overflow: "hidden",
                            mx: "auto",
                            mb: 20,
                            textAlign: "left",
                            cursor: "pointer",
                          }}
                        >
                          <Box
                            sx={{
                              height: 320,
                              width: "100%",
                              backgroundImage:
                                "url(/assets/image/loadingMusic.gif)",
                              backgroundSize: "50%",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                            }}
                          >
                            <object
                              id="main2"
                              data={url}
                              width="100%"
                              height="100%"
                              style={{ cursor: "pointer" }}
                            />
                          </Box>
                          <Box sx={{ p: 16, pb: 0, bgcolor: "#fff", mt: -20 }}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                "svg:hover": {
                                  color: "#000",
                                },
                              }}
                            >
                              <Box>
                                <Typography
                                  variant="h6"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  {name}
                                </Typography>
                                {/* <Typography variant="h6">6/1/22</Typography> */}
                              </Box>
                              <Box
                                sx={{
                                  "button:disabled, button[disabled]": {
                                    border: "none",
                                  },
                                  mt: -10,
                                }}
                              >
                                <StyledTooltip
                                  title={!card.hasUserVoted ? "Vote" : "Unvote"}
                                  arrow={true}
                                  placement="top"
                                  sx={{
                                    "& .MuiTooltip-popper": {
                                      bgcolor: "red",
                                      color: "#fff",
                                    },
                                  }}
                                >
                                  <IconButton
                                    aria-label="contained"
                                    // disabled={!account}
                                    onClick={async () => {
                                      if (!account) await refresh();
                                      if (account) {
                                        upVote({ card, account, hasUserVoted });
                                        if (!hasUserVoted) {
                                          handleOpen();
                                          setSongName(name);
                                        }
                                      }
                                    }}
                                  >
                                    <Icon
                                      icon={
                                        hasUserVoted
                                          ? "fluent:headphones-20-filled"
                                          : "fluent:headphones-48-regular"
                                      }
                                      width={22}
                                      color={
                                        hasUserVoted
                                          ? !account
                                            ? "#939694"
                                            : "#000"
                                          : "#939694"
                                      }
                                    />
                                    {/* </TwitterShareButton> */}
                                  </IconButton>
                                </StyledTooltip>
                                <TwitterShare
                                  open={open}
                                  songName={songName}
                                  handleClose={handleClose}
                                />
                                <Typography
                                  sx={{
                                    textAlign: "center",
                                    color: hasUserVoted ? "#000" : "#939694",
                                    fontWeight: hasUserVoted
                                      ? "bold"
                                      : "normal",
                                  }}
                                >
                                  {count}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    );
                  }
                })
              )}

            </Grid>
            <Box sx={{ textAlign: 'center' }}>
              <Button
                onClick={handleLoadMore}
                disabled={cardArray?.length < cardToShow ? true : false }
                sx={{
                  mx: "auto",
                  fontSize: 16,
                  borderRadius: 25,
                  boxShadow: 23,
                  px: 24,
                  py: 6,
                  fontWeight: "normal",
                  color: "#414141",
                  my: 50
                }}
              >
                <AddIcon sx={{ mr: 5 }} /> Load more
              </Button>
            </Box>
          </Scrollbar>

        </Box>
        <SectionB refresh={refresh} />
        <LeaderBoard />
      </Box>
    </>
  );
}
