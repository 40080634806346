import { Box } from "@mui/material";
import Header from "../Components/Header";

export default function HeroSection() {
  return (
    <Box>
      <Header />
    </Box>
  );
}
