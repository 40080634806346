import { Box, Typography } from "@mui/material";
import { cardBg } from "../../style/style";

export default function SliderCard({ card }) {
  return (
    <Box
      sx={{
        maxWidth: 320,
        width: "100%",
        height: 563,
        ...cardBg,
        overflow: "hidden",
        mb: 20,
        textAlign: "left",
      }}
    >
      <Box
        sx={{
          height: 314,
          "& div": {
            overflow: "hidden",
          },
        }}
      >
        <object
          id="main2"
          data={card.image}
          width="100%"
          height="100%"
          style={{ cursor: "pointer", overflow: "hidden" }}
        ></object>
      </Box>
      <Box sx={{ p: 16, bgcolor: "#fff" }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {card.title}
        </Typography>
        <Typography variant="h6">{card.desc}</Typography>
      </Box>
    </Box>
  );
}
