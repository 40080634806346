import { Box } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
// material

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
   flexGrow: 1,
   height: '100%',
   overflow: 'hidden',
});

const SimpleBarStyle = styled(SimpleBarReact)(({ theme, }) => ({
   maxHeight: '100%',
   '& .simplebar-scrollbar': {
      '&:before': {
         backgroundColor: alpha(theme.palette.grey[500], 0.48),
      },
      '&.simplebar-visible:before': {
         opacity: 1,
      },
   },
   '& .simplebar-track.simplebar-vertical': {
      width: 10,
   },
   '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
      height: 6,
   },
   '& .simplebar-mask': {
      zIndex: 'inherit',
   },
}));

// ----------------------------------------------------------------------

Scrollbar.propTypes = {
   children: PropTypes.node.isRequired,
   sx: PropTypes.object,
};

export default function Scrollbar({ children, sx, ...other }) {
   const isMobile =
      /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
         navigator.userAgent
      );

   if (isMobile) {
      return (
         <Box
         autoHide={false}
            sx={{
               overflowX: 'auto',
               ...sx,
            }}
            {...other}
         >
            {children}
         </Box>
      );
   }

   return (
      <RootStyle>
         <SimpleBarStyle
            timeout={500}
            clickOnTrack={false}
            sx={sx}
            {...other}
         >
            {children}
         </SimpleBarStyle>
      </RootStyle>
   );
}
